<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-17 11:10:50
 * @LastEditors: 杨慢慢
 * @LastEditTime: 2024-01-26 17:32:57
 * @FilePath: \yda_web_manage\src\views\seal\apply\index.vue
 * @Description: 用印申请列表3.0
-->

<template>
  <template v-if="pageLoading">
    <a-spin size="large" class="loading-animation" />
  </template>
  <template v-else>
    <section style="margin: 0 24px">
      <!-- 筛选模块 -->
      <section class="form-style">
        <a-form layout="inline" ref="formRef" :model="formState">
          <!-- 搜索 -->
          <a-form-item label="" class="form-center" name="searchVal">
            <a-input-group compact>
              <a-select
                v-model:value="formState.searchType"
                style="width: 130px"
              >
                <a-select-option
                  v-for="(value, key) in searchTypeOptions"
                  :key="key"
                  :value="value.code"
                  >{{ value.desc }}</a-select-option
                >
              </a-select>
              <a-input
                style="width: 227px; height: 32px"
                v-model:value.trim="formState.searchVal"
                :maxlength="30"
                placeholder="请输入"
                allowClear
              />
            </a-input-group>
          </a-form-item>
          <!-- 申请状态 -->
          <a-form-item label="申请状态：" name="applyStatus">
            <a-select
              v-model:value="formState.applyStatus"
              allowClear
              placeholder="请选择"
              class="form-select"
            >
              <a-select-option
                v-for="(value, key) in examineStatusObj"
                :key="key"
                :value="key"
                >{{ value.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <!-- 用印状态 -->
          <a-form-item label="流程状态：" name="sealStatus">
            <a-select
              v-model:value="formState.sealStatus"
              allowClear
              class="form-select"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(value, key) in sealStatusObj"
                :key="key"
                :value="key"
              >
                {{ value.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- 业务类型 -->
          <a-form-item label="业务类型：" name="processType">
            <a-select
              v-model:value="formState.processType"
              allowClear
              class="form-select"
              placeholder="请选择"
            >
              <a-select-option
                v-for="(value, key) in processTypeObj"
                :key="key"
                :value="key"
              >
                {{ value.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- 流程名称 -->
          <a-form-item label="流程名称：" name="businessProcess">
            <a-input
              style="width: 146px; height: 32px"
              v-model:value.trim="formState.businessProcess"
              :maxlength="30"
              placeholder="请输入"
              allowClear
            />
          </a-form-item>
          <!-- 提交时间 -->
          <a-form-item label="提交时间：" name="submitTime">
            <a-range-picker
              :disabledDate="disabledDate"
              separator="→"
              allowClear
              v-model:value="formState.submitTime"
            >
              <template #suffixIcon>
                <CalendarOutlined />
              </template>
            </a-range-picker>
          </a-form-item>
          <!-- 重置/查询 -->
          <a-form-item class="form-btns">
            <a-button @click="reset">重置</a-button>
            <a-button @click="searchList" class="form-btn">查询</a-button>
          </a-form-item>
        </a-form>
      </section>
      <!-- 表格数据 -->
      <section class="table-style">
        <div class="table-head">
          <span class="title">用印列表</span>
          <div class="btn-block">
            <a-button
              class="add-btn"
              @click="showConfirm"
              v-btn="'PC_SEAL_APPLY'"
              ><PlusOutlined /> 添加申请</a-button
            >
            <a-button @click="exportTable" v-btn="'PC_SEAL_APPLY_EXPORT'"
              ><i class="iconfont icon-daochu head-export" />导出</a-button
            >
          </div>
        </div>
        <a-table
          :columns="applyColumns"
          :dataSource="dataSource"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="tableLoading"
          rowKey="documentId"
          ref="tableRef"
          :scroll="{ x: 400 }"
        >
          <!-- 序号 -->
          <template #id="{ index }">{{
            (pagination.index - 1) * pagination.pageSize + index + 1
          }}</template>
          <!-- 流程名称 -->
          <template #processName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 流程主题 -->
          <template #fileName="{ record, text }">
            <div class="file-name-box">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                  <template #title>
                    <span>{{ text }}</span>
                  </template>
                  {{ text ?? '-' }}
                </a-tooltip>
                <span v-else>{{ text ?? '-' }}</span>
              </div>
              <a-tag class="file-tag" v-if="record.terminated">已结束</a-tag>
            </div>
          </template>
          <!-- 申请人 -->
          <template #staffName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 4">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!--部门 -->
          <template #departmentName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 申请印章 -->
          <template #sealNames="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 6">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 申请次数 -->
          <template #applySums="{ record, text }">
            <span class="applySums">{{
              record.sealType === 2 ? '-' : text
            }}</span>
          </template>
          <!-- 预计用印日期 -->
          <template #sealTime="{ text }">
            <span class="sealTime" :title="text">{{ text ?? '-' }}</span>
          </template>
          <template #sealShowVOList="{ text }">
            <span class="seal-list" :title="text">{{ text ?? '-' }}</span>
          </template>
          <!-- 自助申请 -->
          <template #selfUserName="{ text }">
            <span class="selfUserName" :title="text">{{ text ?? '-' }}</span>
          </template>
          <!-- 操作 -->
          <template #action="{ record }">
            <div class="table-action-box">
              <!-- 查看 -->
              <a @click="check(record)" class="table-text-color">查看</a>
              <a v-if="record?.showProcessTerminate" @click="confirmEnd(record)"
                >结束流程</a
              >
              <!-- <a-dropdown
                v-if="
                  (record?.ableCancel && record?.ableReSubmit) ||
                    (record?.ableCancel && record?.showProcessTerminate) ||
                    (record?.ableReSubmit && record?.showProcessTerminate)
                "
                class="dropdown-more"
                placement="bottomCenter"
              >
                <a @click.prevent>
                  更多
                  <DownOutlined style="margin-left: 4px" />
                </a>
                <template #overlay>
                  <a-menu class="dropdown-menu">
                    <a-menu-item v-if="record?.ableCancel" key="1" class="dropdown-item">
                      <a @click="secondaryConfirmation('backout', record)">撤销</a>
                    </a-menu-item>
                    <a-menu-item v-if="record?.ableReSubmit" key="2" class="dropdown-item">
                      <a @click="reSubmit(record)">重新提交</a>
                    </a-menu-item>
                    <a-menu-item v-if="record?.showProcessTerminate" key="3" class="dropdown-item">
                      <a @click="confirmEnd(record)">结束流程</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown> -->
              <!-- <div v-else>
                <a v-if="record?.ableCancel" class="table-text-color" @click="secondaryConfirmation('backout', record)"
                  >撤销</a
                >
                <a v-if="record?.ableReSubmit" @click="reSubmit(record)" class="table-text-color">重新提交</a>
                <a v-if="record?.showProcessTerminate" @click="confirmEnd(record)" class="table-text-color">结束流程</a>
              </div>-->
            </div>
          </template>
          <!-- 申请状态 -->
          <template #examineStatus="{ text }">
            <span
              :style="{ background: examineStatusObj[text]?.color }"
              class="dottos"
            ></span>
            <span>{{ examineStatusObj[text]?.name }}</span>
          </template>
          <!-- 用印状态 -->
          <template #sealStatus="{ record, text }">
            <span
              class="dottos"
              :style="{ background: sealStatus[text]?.color }"
              v-if="record.sealType !== 2"
            ></span>
            <span>{{
              record.sealType !== 2 ? sealStatus[text]?.status : '-'
            }}</span>
          </template>
        </a-table>
      </section>
      <!-- 选择流程名称弹窗 -->
      <a-modal
        v-model:visible="bProcessVisible"
        title="选择流程名称"
        @ok="selectProcess"
        @cancel="cancelSelect"
        centered
      >
        <a-form
          layout="inline"
          ref="modelRef"
          :model="modelState"
          :rules="rulesRef"
        >
          <a-form-item label="所在部门" name="selectDept">
            <a-select
              v-model:value="modelState.selectDept"
              show-search
              allowClear
              class="form-select"
              placeholder="请选择所在部门"
              style="width: 334px"
              :filter-option="filterOption"
              @change="changeDept"
              :disabled="modelState.deptOptions.length === 1"
            >
              <!-- TODO:联调所在部门接口 -->
              <a-select-option
                v-for="(value, key) in modelState.deptOptions"
                :key="key"
                :value="value.departmentId"
              >
                <div class="name-box">
                  <template v-if="value.departmentId !== value.ownershipId">
                    <div class="dept-name">
                      {{ value.departmentName }}
                    </div>
                    -
                  </template>
                  <div class="company-name">{{ value.ownershipName }}</div>
                </div>
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="流程名称" name="bProcess">
            <a-select
              v-model:value="modelState.bProcess"
              show-search
              allowClear
              class="form-select"
              placeholder="请选择用印流程名称"
              style="width: 334px"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(value, key) in modelState.bOptions"
                :key="key"
                :value="value.processId"
                :label="value.processName"
              >
                {{ value.processName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
      <!-- 申请用印抽屉 -->
      <div class="drawer-box">
        <a-drawer
          title="申请用印"
          :width="533"
          :getContainer="false"
          :visible="drawerVisible"
          :maskClosable="false"
          @close="onClose"
          :bodyStyle="bodyStyle"
          :destroyOnClose="true"
        >
          <DiyForm
            :approvalFlow="approvalFlow"
            @onClose="onClose"
            @onSubmit="onClose"
            @reselect="onReselect"
          />
        </a-drawer>
      </div>
    </section>
    <revoke-modal
      v-model:visible="revokeVisible"
      title="撤销申请"
      @bindSubmit="bindSubmit"
    ></revoke-modal>
    <!-- verbVisible -->
  </template>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  createVNode,
  computed
} from 'vue'
import {
  ExclamationOutlined,
  CalendarOutlined,
  PlusOutlined,
  DownOutlined
} from '@ant-design/icons-vue'
import { Modal, notification, message } from 'ant-design-vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { applyColumns } from './columns'
import DiyForm from '../../../components/diyForm/index.vue'
import {
  getApply,
  exportApply,
  revocationReason,
  getProcessDetail,
  endApply,
  getAllDept,
  checkProcess,
  getProcessList
} from '@/apis/seal'
import { getEnums } from '@/apis/user'
import {
  availableProcess,
  isAvailableProcess,
  canResubmit
} from '@/apis/businessManage'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import { useStore } from 'vuex'
import {
  examineStatusObj,
  paginationParams,
  sealStatus,
  sealStatusObj,
  sealType,
  processTypeObj
} from '@/utils/constData'
import revokeModal from './components/revokeModal.vue'
import { SET_APPLY_PROCESS_LIST } from '@/store/globalData/mutations-type'
import moment from 'moment'
import { useDealDiyFormData } from '@/utils/diyFormHooks'
import disable$ from 'dingtalk-jsapi/api/ui/pullToRefresh/disable'
import { getLineFileList } from '@/apis/elecApply'

export default defineComponent({
  name: 'apply',
  components: {
    PlusOutlined,
    CalendarOutlined,
    revokeModal,
    DiyForm,
    DownOutlined
  },
  setup: () => {
    const pageLoading = ref(true)
    const drawerVisible = ref(false)
    const bodyStyle = ref({
      padding: '0px'
    })
    const store = useStore()
    const tableRef = ref()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      searchVal: undefined,
      applyStatus: undefined,
      sealStatus: undefined,
      timeSort: undefined,
      pagination: {
        ...paginationParams
      },
      dataSource: [],
      applyProcessList: [], // 用印流程
      tableLoading: true,
      userId: store.state.user.userInfo.userId,
      currentNum: 1,
      jurisdiction: store.state.router.navMenus,
      examineStatusObj: examineStatusObj,
      revokeVisible: false,
      revokeDocumentId: null, //撤销文件id
      processInstanceId: null, //流程名称示例id
      searchTypeOptions: [],
      approvalFlow: null // 重新提交审批流程数据使用
    })
    const bProcessVisible = ref(false)
    const verbVisible = ref(false) // 结束流程操作的确认弹窗开关
    const formRef = ref()
    const modelRef = ref()
    const formState = reactive({
      searchVal: undefined, //搜索框[政务版/企业版]
      searchType: 1, //搜索类型[企业版]
      applyStatus: undefined, //审批状态
      sealStatus: undefined, //用印状态
      businessProcess: undefined, //流程名称
      processType: undefined, //业务类型
      submitTime: [] //提交时间
    })
    //选择流程名称
    const modelState = reactive({
      bProcess: undefined,
      selectDept: undefined, //TODO:默认主部门，未设置部门显示当前企业【数组第一项】
      bOptions: [], //流程名称选项
      deptOptions: [] //部门选项
    })
    //流程名称校验
    const rulesRef = reactive({
      bProcess: [
        {
          required: true,
          message: '请选择流程名称'
        }
      ],
      selectDept: [
        {
          required: true,
          message: '请选择所在部门',
          type: 'number'
        }
      ]
    })
    // 尝试hooks
    const { dealFormDataAndSealData, dealResubmitData } = useDealDiyFormData()
    //流程名称元数据下拉选择框模糊搜索功能
    const filterOption = (input, option) => {
      console.log('input', input)
      console.log('option', option.label)
      return option.label.indexOf(input) > -1
    }

    //流程名称弹窗确定事件
    const selectProcess = () => {
      modelRef.value.validate().then(() => {
        //校验当前选择的流程名称是否可用
        isAvailableProcess({ processId: modelState.bProcess })
          .then((res) => {
            // TODO:核对接口是否需要增加部门Id参数【不需要】
            let obj = { processId: modelState.bProcess }
            notification.destroy()
            bProcessVisible.value = false
            modelState.bProcess = undefined
            if (res.success) {
              store.commit('SET_DEPARTMENT_ID', modelState.selectDept)
              getFormItemData(obj)
              return
            }
            Modal.confirm({
              centered: true,
              title: `${res.message}`, //TODO:【部门被删除时提醒】当前选中的部门已删除，请联系管理员处理
              icon: createVNode(ExclamationOutlined),
              okText: '重新选择流程名称', //TODO:重新选择部门
              onOk() {
                //重新选择流程名称/所在部门
                // bProcessVisible.value = true
                showConfirm()
              }
            })
          })
          .catch((error) => {
            console.log(error)
          })
        // router.push('/seal/apply/add?status=1')
        bProcessVisible.value = false
      })
    }
    //流程名称弹窗取消事件
    const cancelSelect = () => {
      if (store.state.diyForm.recordId) {
        store.commit('SET_RESUBMIT_RECORD', { recordId: null })
      }
      modelState.bProcess = undefined
      // TODO：所在部门
      modelState.selectDept = undefined
      modelRef.value.clearValidate()
    }
    // 日期选择器：当天之后不可选
    const disabledDate = (current) => {
      return current && current > moment().endOf('day')
    }

    //获取表单
    const getList = async () => {
      state.tableLoading = true
      const params = {
        pageSize: state.pagination.pageSize,
        pageIndex: state.pagination.current,
        sealStatus: formState.sealStatus, //用印状态
        examineStatus: formState.applyStatus, //审批状态
        search: formState.searchVal, //模糊查询内容
        startTime: formState.submitTime.length
          ? `${moment(formState.submitTime[0]).format('YYYY-MM-DD')} 00:00:00`
          : '', //开始时间
        endTime: formState.submitTime.length
          ? `${moment(formState.submitTime[1]).format('YYYY-MM-DD')} 23:59:59`
          : '', //结束时间
        searchType: formState.searchType, //搜索项类型
        processNameSearch: formState.businessProcess, //流程名称模糊查询
        processType: formState.processType, //业务类型
        timeSort: state.timeSort //提交申请时间字段升序/降序
      }
      const res = await getApply(params)
      state.dataSource = res.data
      state.dataSource.map((item) => {
        let sealShowVOList = item.sealShowVOList || []
        const sealNameList = sealShowVOList?.map((item) => {
          return item.sealName
        })
        const applySums = sealShowVOList?.map((item) => {
          return item.applicationNumber
        })
        item.sealNames = sealNameList.join(',')
        item.applySums =
          applySums.length >= 1
            ? applySums.reduce((preValue, curValue) => {
                return preValue + curValue
              })
            : 0
      })
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }

    // 根据类型判断使用什么接口获取数据
    const getFormItemData = (data, type) => {
      type === 'resubmit' ? resubmitGetFormData(data) : addGetFormData(data)
    }
    // 新增申请时获取动态表单数据
    const addGetFormData = async (obj) => {
      const res = await getProcessDetail(obj)
      state.approvalFlow = []
      const recordId = JSON.parse(JSON.stringify(store.state.diyForm.recordId))
      store.commit('EMPTY_ALL_DATA')
      if (recordId) store.commit('SET_RESUBMIT_RECORD', { recordId })
      if (res.code === 0) {
        dealFormDataAndSealData(
          res.data.processId,
          res.data.processDefinitionId,
          JSON.parse(res.data.formItems),
          res.data.sealList,
          false,
          res.data.remoteAuditorVO,
          res.data.hasSelf,
          res.data
        )
        drawerVisible.value = true
      }
      if (obj.pdfUrl != null) {
      }
    }
    // 重新提交信息查询接口
    const resubmitGetFormData = async (id) => {
      // 获取之前填写的表单数据
      const res = await canResubmit(id)
      // 同步记录ID
      store.commit('SET_RESUBMIT_RECORD', { recordId: id })
      if (res.success) {
        if (res.data.code === 0) {
          if (!res.data.sealList || res.data.sealList.length <= 0) {
            notification['error']({
              message: '提示',
              description: '当前流程名称无印章配置,请先配置印章'
            })
            return
          }
          // 审批流程信息
          state.approvalFlow = res.data.flowInfoVOList
          // 同步回显数据
          await dealResubmitData(
            res.data.formData,
            res.data.formItems,
            res.data.sealList,
            res.data.fileElseList
          )
          // 同步控件列表和form
          dealFormDataAndSealData(
            res.data.processId,
            res.data.processDefinitionId,
            JSON.parse(res.data.formItems),
            res.data.sealList,
            true,
            res.data.remoteAuditorVO,
            res.data.hasSelf,
            res.data
          )
          drawerVisible.value = true
        } else {
          Modal.warning({
            centered: true,
            title: `业务流程修改`,
            icon: createVNode(ExclamationOutlined),
            content: '当前业务流程有修改，请重新填写',
            okText: '确定',
            onOk() {
              addGetFormData({ processId: res.data.processId })
            }
          })
        }
        return
      }
      if (res.code === 8003 || res.code === 8004) {
        //业务流程停用|业务流程不可用
        Modal.confirm({
          centered: true,
          title: `${res.code === 8003 ? '' : '业务流程不可用'}`,
          icon: createVNode(ExclamationOutlined),
          content: res.message,
          cancelText: '确定',
          okText: '重新选择业务流程',
          onOk() {
            onReselect()
          },
          onCancel() {
            store.commit('SET_RESUBMIT_RECORD', { recordId: null })
            console.log('取消了')
          }
        })
      }
      if (res.code === 8022) {
        notification.destroy()
        Modal.warning({
          centered: true,
          title: `所在部门变更`,
          icon: createVNode(ExclamationOutlined),
          content: '发起流程所在部门发生变更，请重新填写',
          okText: '确定',
          onOk() {
            showConfirm()
          }
        })
      }
    }
    //通过部门id切换流程名称
    const changeProcess = () => {
      // TODO:前置校验-新接口-判断该用户所有部门下是否存在流程名称，若均没有流程名称，则警告拦截
      checkProcess()
        .then((res) => {
          console.log('校验是否有有可用流程名称', res)
          if (res.data) {
            // TODO：通过部门id(modelState.selectDept)查询流程名称
            getProcessList({ deptId: modelState.selectDept })
              .then((res) => {
                console.log('查询对应的流程名称', res)
                if (res.success) {
                  modelState.bOptions = res.data
                }
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            // 没有可用的流程名称
            notification['warning']({
              message: '提示',
              description: '暂无可用流程名称'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    //添加申请
    const showConfirm = async () => {
      //TODO: 获取部门列表的所有数据
      getDeptList()
      changeProcess()
      bProcessVisible.value = true
      // TODO:接口增加部门id参数
      // availableProcess() //查询当前员工可用流程名称
      // .then(res => {
      //     // if (res.data.length === 0) {
      //     //   // 没有可用的流程名称
      //     //   notification['warning']({
      //     //     message: '提示',
      //     //     description: '暂无可用流程名称'
      //     //   })
      //     //   return
      //     // }
      //     /*
      //     TODO：
      //       默认选中主部门，若该人员未设置部门，则默认选中当前企业
      //       modelState.selectDept=
      //       通过部门id获取流程名称数据并赋值
      //     */
      // if (res.success) {
      // modelState.bOptions = res.data
      // }
      // })
      // .catch(error => {
      //   console.log(error)
      // })
    }
    // 添加申请按钮
    const exportTable = () => {
      let sessionId = localStorage.getItem('yda-qywx-sessionId')
      if (sessionId == 'null' || !sessionId) {
        sessionId = undefined
      }
      // 导出用印申请记录
      window.location = exportApply({
        sealStatus: formState.sealStatus, //用印状态
        examineStatus: formState.applyStatus, //审批状态
        search: formState.searchVal, //模糊查询内容
        startTime: formState.submitTime.length
          ? `${moment(formState.submitTime[0]).format('YYYY-MM-DD')} 00:00:00`
          : '', //开始时间
        endTime: formState.submitTime.length
          ? `${moment(formState.submitTime[1]).format('YYYY-MM-DD')} 00:00:00`
          : '', //结束时间
        searchType: formState.searchType, //搜索项类型
        processNameSearch: formState.businessProcess, //模糊查询
        processType: formState.processType, //业务类型
        timeSort: state.timeSort, //提交申请时间字段升序/降序
        sessionId
      })
      setTimeout(() => {
        cmsNotice('success', '正在为您导出，请耐心等待...')
      }, 200)
    }
    const handleTableChange = ({ current, pageSize }, filters, { order }) => {
      // 排序
      state.timeSort = ''
      if (order === 'ascend') {
        state.timeSort = 1
      }
      state.dataSource = []
      // 页码change改变
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }

    // 查询
    const searchList = () => {
      state.pagination.current = 1
      getList()
    }

    //重置筛选条件(3.4.0本地有缓存的条件功能，新增条件的记得加一下重置)
    const formRest = () => {
      formState.searchVal = undefined
      formState.searchType = 1
      formState.applyStatus = undefined
      formState.sealStatus = undefined
      formState.businessProcess = undefined
      formState.processType = undefined
      formState.submitTime = []
    }

    //重置
    const reset = () => {
      formRef.value.resetFields()
      formRest()
      console.log(formState.submitTime)
      formState.searchType = 1
      state.pagination.current = 1
      getList()
    }

    //撤销
    const secondaryConfirmation = async (type, currentItem) => {
      console.log('当前对象', currentItem)
      if (type === 'backout') {
        state.revokeDocumentId = currentItem.documentId
        state.processInstanceId = currentItem.processInstanceId
        state.revokeVisible = true
      }
    }

    //确认撤销
    const bindSubmit = async (params) => {
      const res = await revocationReason({
        documentId: state.revokeDocumentId, //用印申请文件id
        processInstanceId: state.processInstanceId, //流程名称实例id
        withdrawCause: params.revoke //撤销原因
      })
      res.success && cmsNotice('success', '您的申请已撤销成功！')
      getList()
    }
    //TODO:获取部门列表
    const getDeptList = () => {
      modelState.deptOptions = []
      getAllDept()
        .then((res) => {
          console.log('获取所在部门', res)
          if (res.success) {
            res.data.forEach((element) => {
              console.log('ele', element)
              modelState.deptOptions.push({
                departmentId: element.departmentId,
                departmentName: element.departmentName,
                ownershipId: element.ownershipId,
                ownershipName: element.ownershipName
              })
            })
            modelState.selectDept = modelState.deptOptions[0].departmentId
            console.log(
              'modelState.deptOptions',
              modelState.deptOptions,
              modelState.deptOptions[0]
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    onMounted(async () => {
      console.log('执行了吗')

      await getEnums({ kind: 'DOCUMENT_SEARCH_TYPE' }).then((res) => {
        if (res.success) {
          state.searchTypeOptions = res.data
          for (let i = 0; i < state.searchTypeOptions.length; i++) {
            if (state.searchTypeOptions[i].code === 4) {
              state.searchTypeOptions.splice(i, 1)
              break
            }
          }
        }
      })
      //判断是否存在缓存参数
      const params = store.state.sealManage.sealParams
      if (params) {
        mergeParams(params)
      }
      await getList()
      pageLoading.value = false
      //合法性审查跳转
      let btDataStr = localStorage.getItem('iframeData')
      if (btDataStr != null) {
        let btData = JSON.parse(btDataStr)
        localStorage.removeItem('iframeData')
        getFormItemData({
          processId: btData.processId,
          pdfUrl: btData.pdfUrl,
          flowName: btData.flowName
        })
      }
    })
    onBeforeRouteLeave(() => {
      Modal.destroyAll()
    })

    watch(
      () => store.state.user.userInfo.enterpriseId,
      () => {
        getList()
      }
    )

    //重新提交
    const reSubmit = (record) => {
      console.log('重新提交', record)
      store.commit('SET_DEPARTMENT_ID', record.departmentId)
      getFormItemData(record.documentId, 'resubmit')
    }

    // 确认结束流程
    const confirmEnd = (record) => {
      Modal.confirm({
        title: '是否结束流程！',
        icon: createVNode(ExclamationOutlined),
        content: '流程结束后将不能继续审批/用印，且不能恢复',
        okText: '确定',
        cancelText: '取消',
        centered: true,
        onCancel() {},
        async onOk() {
          const res = await endApply(record.documentId)
          res.success && cmsNotice('success', '您的流程已结束！')
          getList()
        }
      })
    }

    //查看
    const check = (record) => {
      router.push({
        path: '/seal/apply/detail',
        query: {
          documentId: record.documentId
          // processInstanceId: record.processInstanceId
        }
      })
    }

    const onClose = (status) => {
      drawerVisible.value = false
      store.commit('EMPTY_ALL_DATA')
      if (status) getList()
    }
    // 申请-需要重新选择流程名称时使用
    const onReselect = (type) => {
      if (!type) {
        showConfirm()
      } else {
        addGetFormData({ processId: store.state.diyForm.processId })
      }
    }

    //监听筛选参数变化
    watch(
      () => formState,
      () => {
        store.commit('sealManage/SET_SEALPARAMS', {
          ...formState,
          current: state.pagination.current,
          pageSize: state.pagination.pageSize
        })
      },
      { deep: true }
    )

    //监听分页
    watch(
      () => state.pagination,
      () => {
        store.commit('sealManage/SET_SEALPARAMS', {
          ...formState,
          current: state.pagination.current,
          pageSize: state.pagination.pageSize
        })
      },
      { deep: true }
    )

    //合并缓存的参数
    const mergeParams = (params) => {
      formState.searchVal = params.searchVal
      formState.searchType = params.searchType
      formState.applyStatus = params.applyStatus
      formState.sealStatus = params.sealStatus
      formState.businessProcess = params.businessProcess
      formState.processType = params.processType
      formState.submitTime = params.submitTime
      state.pagination.current = params.current
      state.pagination.pageSize = params.pageSize
    }
    //切换所在部门
    const changeDept = (e) => {
      console.log('选了谁', e)
      //TODO：根据所选的部门Id,获取对应的流程名称
      modelState.selectDept = e
      modelState.bOptions = []
      modelState.bProcess = undefined
      changeProcess()
    }
    return {
      ...toRefs(state),
      searchList,
      reset,
      exportTable,
      handleTableChange,
      tableRef,
      formState,
      formRef,
      showConfirm,
      disabledDate,
      secondaryConfirmation,
      sealStatus,
      sealStatusObj,
      processTypeObj,
      sealType,
      reSubmit,
      confirmEnd,
      check,
      bindSubmit,
      drawerVisible,
      onClose,
      onReselect,
      bodyStyle,
      bProcessVisible,
      verbVisible,
      modelState,
      rulesRef,
      selectProcess,
      modelRef,
      cancelSelect,
      applyColumns,
      pageLoading,
      filterOption,
      changeDept,
      getDeptList
    }
  }
})
</script>

<style lang="scss" scoped>

::v-deep .ant-input-affix-wrapper-focused{
  border-color: #0A7BFF !important;
  box-shadow: none !important;
}

.printer {
  width: 334px;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 12px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .default-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #bfbfbf;
  }
  .down-arrow {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
  }
}
.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.drawer-box {
  :deep(.ant-form-item-control) {
    line-height: inherit;
    .ant-calendar-picker {
      width: 100% !important;
    }
  }
  :deep(.ant-drawer-header) {
    position: fixed;
    width: 533px;
    z-index: 2;
  }
  :deep(.ant-drawer-body) {
    margin-top: 54px;
  }
}
// ::v-deep .ant-select {
//   .ant-select-selector {
//     height: 32px;
//     // border-radius: 4px;
//     line-height: 32px;
//   }
//   .ant-select-selection-placeholder {
//     line-height: 32px;
//   }
//   .ant-select-selection-item {
//     line-height: 32px;
//   }
// }

.dottos {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.seal-list {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.seal-list {
  max-width: 250px;
}
.form-center {
  margin-bottom: 16px;
}
//操作
.table-style {
  margin-right: 16px;
}
.table-text-color {
  margin-right: 16px;
}
.table-text-warn {
  color: rgb(245, 34, 45);
}

.add-application-options {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.text-box {
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file-name-box {
  display: flex;
  flex-wrap: nowrap;
  .file-tag {
    margin: 0 0 0 3px;
    color: #999999;
    padding: 0px 6px;
    border: 1px solid #e9e9e9;
    background: #f9f9f9;
    border-radius: 2px;
  }
}
.dropdown-more {
  color: #1890ff;
  font-size: 14px;
}
.dropdown-menu {
  width: 95px;
  border-radius: 4px;

  :deep(.ant-dropdown-menu-item) {
    text-align: center;
    font-size: 12px;
    color: #333333;

    &:hover {
      background: rgba($color: #1890ff, $alpha: 0.05);
    }
  }
}
.table-action-box {
  display: flex;
  justify-content: start;
}
.name-box {
  display: flex;
  .dept-name {
    max-width: 8em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .company-name {
    max-width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
