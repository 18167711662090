<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-19 10:54:42
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-08 15:21:45
 * @FilePath: \yda_web_manage\src\components\diyForm\components\multipleSelect.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <a-select
    v-bind="$attrs"
    mode="multiple"
    :showArrow="true"
    :placeholder="$attrs.props.placeholder || '请选择'"
    @change="onChange($event, $attrs)"
    :getPopupContainer="trigger => trigger.parentElement"
  >
    <a-select-option
      v-for="(item, index) in $attrs.props.options || []"
      :key="index"
      :value="getValue(item, 'value')"
      >{{ getValue(item, 'label') }}</a-select-option
    >
  </a-select>
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>
<script setup>
import { computed, defineEmits } from 'vue'
// import tips from './tips.vue'
import { useStore } from 'vuex'
const store = useStore()
const getValue = computed(() => (item, type) => {
  if (typeof item === 'string') return item
  return item[type]
})
const emit = defineEmits(['change'])
const onChange = (e, data) => {
  // if (data.controlName && data.controlName === 'REMOTE') {
  //   store.commit('SET_SHOW_CONFIRMER', e.includes('2'))
  // }
  // console.log(e, '6666', data)
  emit('change')
}
</script>
<style lang="less" scoped>
:deep(.ant-select-selector) {
  height: auto !important;
  min-height: 32px !important;
  line-height: normal !important;
}
:deep(.ant-select-selection-item) {
  line-height: 22px !important;
}
</style>
