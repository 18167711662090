<template>
  <div class="file-upload-box">
    <div
      v-if="state.fileList.length > 0 && $attrs.props.isAnnex"
      class="file-item-box"
    >
      <div
        v-for="(item, index) in state.fileList"
        :key="index"
        class="file-item"
      >
        <img :src="getUrl(item, index)" alt="" class="file-img" />
        <div class="file-name-box">
          <a-tooltip v-if="item.name?.length > 15">
            <template #title>{{ item.name }}</template>
            <p class="file-name">{{ item.name }}</p>
          </a-tooltip>
          <p class="file-name" v-else>{{ item.name }}</p>
          <a-progress
            :percent="item.percent"
            :show-info="false"
            strokeColor="#3171F4"
            :strokeWidth="2"
            class="progress"
            v-if="item.status === 'uploading'"
          />
        </div>
        <p @click="onDelete(index, $attrs.id)">
          <IconFont type="icon-delete" />
        </p>
      </div>
    </div>
    <a-upload
      class="file-item-upload"
      v-bind="$attrs"
      :action="action"
      :show-upload-list="false"
      withCredentials
      :before-upload="beforeUpload"
      @change="handleChange($event, $attrs.id)"
      v-if="$attrs.props.isAnnex"
    >
      <a-button
        class="btn"
        v-if="form[$attrs.id] === undefined || form[$attrs.id].length < 100"
      >
        <img
          style="
            margin-right: 4px;
            vertical-align: middle;
            transform: translateY(-1px);
          "
          src="@/assets/svg/upload.svg"
        />
        上传文件
      </a-button>
    </a-upload>
    <a-select
      show-search
      v-model="flie"
      mode="multiple"
      :options="lineFiles"
      :placeholder="$attrs.props.placeholder || '请选择上传文件'"
      @change="onChange($event, $attrs.id)"
      @blur="onBlur"
      :filterOption="filterOption"
      v-if="!$attrs.props.isAnnex"
    >
    </a-select>
    <!-- <div class="qrcode" @click.stop="setQrcode" v-if="$attrs.fixType == 'FILE' && qrcodeForm.qrcodeType == 1">
      <img
        style="
        width:16px;
        height:16px;
            vertical-align: middle;
           margin-right:4px;
          "
        :src="qrcodeForm.firstshowQrcode ? state.qrcode02 : state.qrcode01"
      />
      <span v-if="!qrcodeForm.firstshowQrcode">二维码水印设置</span>
      <span class="info" v-else>{{ qrcodeInfo() }} <i>设置</i></span>
    </div>
    <SetQrcode v-model:qrcodeVisible="state.qrcodeVisible" @handleOk="handleOk" ref="setQrcodeRef" /> -->
    <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" class="tips" /> -->
  </div>
</template>

<script setup>
import {
  defineEmits,
  reactive,
  computed,
  onMounted,
  useAttrs,
  watch,
  ref
} from 'vue'
import { cmsNotice } from '@/utils/utils'
import { useStore } from 'vuex'
import SetQrcode from './setQrcode.vue'
import {
  getLineFile,
  uploadLineFile,
  uploadLineFileMul
} from '@/apis/elecApply'
// import axios from 'axios' // 引用axios
// import tips from './tips.vue'
const action = process.env.VUE_APP_API_BASE_URL + '/file/upload'
const store = useStore()
const emit = defineEmits(['blur', 'change'])
const flie = ref([])
const lineFiles = ref([])
// const searchlist = ref([])
const form = computed(() => store.state.diyForm.form)
const getUrl = computed(() => (file, index) => {
  const list = [
    {
      type: ['docx', 'doc'],
      url: require('@/assets/images/word.png')
    },
    {
      type: ['xls', 'xlsx'],
      url: require('@/assets/images/excel.png')
    },
    {
      type: ['pdf'],
      url: require('@/assets/images/pdf.png')
    }
    // {
    //   type: ['text'],
    //   url: require('@/assets/images/txt.png')
    // }
  ]
  let url
  const suffix = file.name.split('.').pop()
  for (let index = 0; index < list.length; index++) {
    if (list[index].type.includes(suffix)) {
      url = list[index].url
    }
  }
  if (!url) {
    if (file.url) return file.url
    return URL.createObjectURL(file.originFileObj)
  }
  return url
})

const state = reactive({
  fileList: [], // 图片list
  showQrcode: false,
  qrcodeVisible: false,
  qrcode01: require('@/assets/images/diyForm/qrcode01.png'),
  qrcode02: require('@/assets/images/diyForm/qrcode02.png')
})
const setQrcodeRef = ref(null)

const qrcodeForm = computed({
  get: () => {
    return store.state.diyForm.qrcodeForm
  },
  set: (val) => {
    store.commit('SET_QRCODE_DATA', {
      ...store.state.diyForm.qrcodeForm,
      ...val
    })
  }
})

//二维码水印文字回显
const qrcodeInfo = () => {
  if (qrcodeForm.value) {
    let str = ''
    switch (qrcodeForm.value.qrcodeSize) {
      case 1:
        str += '20*20mm；'
        break
      case 2:
        str += '25*25mm；'
        break
      case 3:
        str += '30*30mm；'
        break
      case 4:
        str += '35*35mm；'
        break
    }

    switch (qrcodeForm.value.qrcodePositionType) {
      case 1:
        str += '左上'
        break
      case 2:
        str += '右上'
        break
      case 3:
        str += '左下'
        break
      case 4:
        str += '右下'
        break
    }
    return str
  }
}

const attrsData = useAttrs()

// 上传前
const beforeUpload = (file) => {
  console.log(file, 'file')
  return new Promise((resolve, reject) => {
    const size = Math.floor(file.size / (1024 * 1024))
    const suffix = file.name?.split('.').pop()
    // const type = ['docx', 'doc', 'xlsx', 'xls', 'pdf', 'text', 'jpg', 'png', 'tif', 'jpeg', 'bmp']
    const type = ['docx', 'doc', 'xlsx', 'xls', 'pdf', 'jpg', 'png', 'jpeg']

    if (size > 50) {
      cmsNotice('error', `大小不能超过50M`)
      return reject(false)
    }
    //开启二维码并且为上传文件（不是附件）
    if (
      qrcodeForm.value.qrcodeType == 1 &&
      attrsData.fixType == 'FILE' &&
      !['docx', 'doc', 'pdf'].includes(suffix.toLowerCase())
    ) {
      cmsNotice('error', `二维码水印功能不支持此文件格式，请上传word或PDF格式`)
      return reject(false)
    }

    if (!type.includes(suffix.toLowerCase())) {
      cmsNotice('error', `格式错误，仅支持${type.join(',')}格式`)
      return reject(false)
    }
    return resolve(true)
  })
}
// 上传文件改变时的状态
const handleChange = (info, id) => {
  console.log('info', info)
  // 去重
  const index = state.fileList.findIndex((item) => item.uid === info.file.uid)
  if (index > -1) {
    state.fileList[index] = { ...info.file }
  } else {
    state.fileList.push(info.file)
  }
  try {
    const {
      file: { status, response }
    } = info
    if (status === 'done') {
      if (response && response.code === 0) {
        let obj = { fileId: response.data[0].id }
        form.value[id] ? form.value[id].push(obj) : (form.value[id] = [obj])
        // if (form.value[id]) {
        //   form.value[id].push({
        //     fileId: response.data[0].id,
        //     fullUrl: response.data[0].fullUrl,
        //     suffix: response.data[0].suffix,
        //     name: response.data[0].name
        //   })
        // } else {
        //   form.value[id] = [
        //     {
        //       fileId: response.data[0].id,
        //       fullUrl: response.data[0].fullUrl,
        //       suffix: response.data[0].suffix,
        //       name: response.data[0].name
        //     }
        //   ]
        // }
        emit('blur')
        return emit('change', 'add', state.fileList)
      } else {
        state.fileList = state.fileList.filter(
          (item) => item.uid !== info.file.uid
        )
        cmsNotice('error', response.message)
        return emit('change', 'error')
      }
    }
    if (status === 'error') {
      state.fileList = state.fileList.filter(
        (item) => item.uid !== info.file.uid
      )
      cmsNotice('error', `上传失败`)
      emit('change', 'error')
    }
  } catch (error) {
    console.log('err', error)
  }
}
// 删除
const onDelete = (index, id) => {
  state.fileList.splice(index, 1)
  form.value[id].splice(index, 1)
  emit('blur')
}

//设置二维码水印
const setQrcode = () => {
  if (setQrcodeRef.value) {
    setQrcodeRef.value.init(qrcodeForm.value)
  }
  state.qrcodeVisible = true
}

const handleOk = (val) => {
  // state.showQrcode = true
  //firstshowQrcode 二维码水印参数回显
  qrcodeForm.value = { ...val, firstshowQrcode: true }
}

const filterOption = (input, option) => {
  return option.label.indexOf(input) >= 0
}

let valueList = []
let valueListId = ''
let params = { fileList: [] }
const onBlur = (e) => {
  console.log('onblur', e)
  console.log('valueList', valueList)
  console.log('valueListId', valueListId)
  if (valueList.length == 0) return
  form.value[valueListId] = []
  flie.value = []
  params = { fileList: [] }
  for (let i = 0; i < valueList.length; i++) {
    let value = valueList[i]
    const index = lineFiles.value.findIndex((item) => item.value === value)
    if (index === -1) return
    console.log('lineFiles.value[index]', lineFiles.value[index])
    flie.value.push(lineFiles.value[index])
    console.log('flie.value222', flie.value)
    params.fileList.push({
      flowName: lineFiles.value[index].label,
      pdfUrl: lineFiles.value[index].pdfUrl
    })
  }
  uploadLineFileMul(params).then((response) => {
    if (response.data) {
      response.data.forEach((i) => {
        let obj = { fileId: i }
        form.value[valueListId].push(obj)
        emit('blur')
      })
      console.log('form.value[valueListId]', form.value[valueListId])
    }
  })
}

const onChange = (value, id) => {
  console.log(value, id)
  console.log(lineFiles.value)
  valueList = value
  valueListId = id

  // const index = lineFiles.value.findIndex((item) => item.value === value)
  // if (index === -1) return
  // flie.value = lineFiles.value[index]
  // const params = {
  //   flowName: lineFiles.value[index].label,
  //   pdfUrl: lineFiles.value[index].pdfUrl
  // }
  // uploadLineFile(params).then((response) => {
  //   let obj = { fileId: response.data }
  //   form.value[id] = [obj]
  //   emit('blur')
  // })
}

const getFile = () => {
  getLineFile().then((res) => {
    if (res.success && res.data.length > 0) {
      res.data.forEach((element, index) => {
        lineFiles.value.push({
          label: element.flowName,
          pdfUrl: element.pdfUrl,
          value: index
        })
      })
      let btDataStr = localStorage.getItem('iframeData')
      if (btDataStr != null) {
        let btData = JSON.parse(btDataStr)
        form.value[attrsData.id] = []
        flie.value = []
        uploadLineFileMul({ fileList: [btData] }).then((response) => {
          if (response.data) {
            response.data.forEach((i) => {
              let obj = { fileId: i }
              console.log('field3631729981952field3631729981952', form.value)
              console.log('field3631729981952field3631729981952', attrsData.id)
              form.value[attrsData.id].push(obj)
              lineFiles.value.forEach((it) => {
                if (btData.pdfUrl == it.pdfUrl) {
                  flie.value.push(it)
                  console.log('flie.value111', flie.value)
                }
              })

              emit('blur')
            })
            console.log('form.value[valueListId]', form.value[attrsData.id])
          }
        })
      }
    }
  })
}

onMounted(() => {
  console.log(attrsData)
  if (form.value[attrsData.id]) {
    state.fileList = JSON.parse(JSON.stringify(form.value[attrsData.id]))
  } else {
    state.fileList = []
  }
  if (!attrsData.props.isAnnex) getFile()
})
watch(
  () => form.value[attrsData.id],
  (val) => {
    if (!val) state.fileList = []
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.progress {
  width: 200px;
}
.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  .file-img {
    width: 44px;
    height: 44px;
    margin-right: 12px;
  }
  .file-name-box {
    width: 224px;
    .file-name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }
  p {
    width: 26px;
    text-align: center;
  }
}
.file-item:hover {
  background: #f5f5f5;
}
.btn {
  height: 32px;
  background: #ffffff;
  // border-radius: 4px;
  border: 1px solid #e6e6e6;
  color: #666666;
  &:hover {
    color: #666666;
  }
}
.tips {
  width: 410px;
}
.file-item-box {
  max-height: 348px;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 100%;
    display: block;
    overflow-y: scroll;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 51, 0.1);
    border-radius: 2px;
  }
}

.qrcode {
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 14px;
    color: #3171f4;
    line-height: 20px;
  }
  .info {
    color: rgba(0, 0, 0, 0.65);
    i {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #3171f4;
      font-style: normal;
    }
  }
}

.file-upload-box {
  :deep(.ant-upload) {
    //初始化样式 （防止其他页面的样式污染这里）
    width: fit-content;
    height: fit-content;
  }
}
</style>
