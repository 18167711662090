/*
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-18 15:57:46
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-04-25 19:59:56
 * @FilePath: \yda_web_manage\src\components\diyForm\componentExport.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import TextInput from './components/textInput.vue'
import TextareaInput from './components/textareaInput.vue'
import NumberInput from './components/numberInput.vue'
import AmountInput from './components/amountInput.vue'
import MultipleSelect from './components/multipleSelect.vue'
import SelectInput from './components/select.vue'
import DateTime from './components/dateTime.vue'
import DateTimeRange from './components/dateTimeRange.vue'
import Address from './components/address.vue'
import FileUpload from './components/fileUpload.vue'
import Switch from './components/switch.vue'
import RemoteStaff from './components/remoteStaff.vue'
import Phone from './components/phone.vue'

export default {
  TextInput,
  TextareaInput,
  NumberInput,
  AmountInput,
  MultipleSelect,
  SelectInput,
  DateTime,
  DateTimeRange,
  Address,
  FileUpload,
  Switch,
  RemoteStaff,
  Phone
}
