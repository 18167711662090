<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-28 11:20:25
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-08 14:25:42
 * @FilePath: \yda_web_manage\src\components\diyForm\components\tips.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <p class="tips">{{ content || '' }}</p>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  content: {
    type: String,
    default: ''
  }
})
</script>
<style lang="less" scoped>
.tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 24px;
}
</style>
