<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-18 15:59:24
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-12 17:46:59
 * @FilePath: \yda_web_manage\src\components\diyForm\components\TextInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <a-input
    v-bind="$attrs"
    :placeholder="$attrs.props.placeholder || '请输入'"
    :maxlength="30"
    @change="onChange($event, $attrs.id)"
  />
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>

<script setup>
import { computed, defineEmits } from 'vue'
// import tips from './tips.vue'
import { useStore } from 'vuex'
const emit = defineEmits(['change'])
const store = useStore()
const form = computed(() => store.state.diyForm.form)
const onChange = (val, id) => {
  if (form.value[id]) {
    form.value[id] = form.value[id].replace(/[^0-9\(\)\-]/g, '')
  }

  emit('change')
}
</script>
<style lang="less" scoped></style>
