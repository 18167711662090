<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-19 18:30:18
 * @LastEditors: 杨慢慢
 * @LastEditTime: 2024-01-08 13:39:52
 * @FilePath: \yda_web_manage\src\components\diyForm\components\SelectInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-select
    v-bind="$attrs"
    v-if="$attrs.titles === '盖印人'"
    disabled
  >
  </a-select>

  <a-select
    v-else-if="$attrs.configName !== 'Remote'"
    v-bind="$attrs"
    v-model="sealForm[$attrs.id]"
    :placeholder="$attrs.props.placeholder || '请选择'"
    @change="onChange($event, $attrs)"
    :getPopupContainer="trigger => trigger.parentElement"
    :disabled="$attrs.props.options.length === 1 && $attrs.id == 'applySeal0'"
  >
    <a-select-option
      v-for="(item, index) in $attrs.props.options || []"
      :key="index"
      :value="getValue(item, 'value')"
      :disabled="item.disabled || false"
      >{{ getValue(item, 'label') }}</a-select-option
    >
  </a-select>

  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>
<script setup>
import { computed, defineEmits, nextTick, onMounted, ref } from 'vue'
import { notification } from 'ant-design-vue'
import tips from './tips.vue'
import { useStore } from 'vuex'
import { useAttrs } from 'vue'
const value1= ref('lucy')
const attrs = useAttrs()
const sealPerData = ref('lucy');
const sealForm = computed(() => store.state.diyForm.sealFormData.forms)
const getValue = computed(() => (item, type) => {
  if (typeof item === 'string') return item
  return item[type]
})
const store = useStore()
const emit = defineEmits(['change'])
//上一次选中对印章ID
const pervSealId = ref('')

const validate = (data, type) => {
  let id = type == 1 ? `applySeal${data.id.split('r')[1]}` : data.id
  let sealId = store.state.diyForm.sealFormData.forms[id]
  let sealerList = store.state.diyForm.sealFormData.keeperObj[sealId] // 当前盖印人
  // 处理没有选择印章直接选择保管员
  if (sealId == undefined && type == 1) {
    nextTick(() => {
      store.state.diyForm.sealFormData.forms[data.id] = undefined
    })
    notification['error']({
      message: '提示',
      description: '请选择印章'
    })
    // console.log('1111111111', store.state.diyForm.sealFormData.forms);
    return
  }
  // 处理选择印章没有盖印人
  if (!sealerList.length) {
    nextTick(() => {
      //更改上一个印章选中的状态
      const sealId = pervSealId.value
      // 判断上一次是否有选中的
      if (sealId) {
        store.commit('UPDATE_SEAL_STATUS', { sealId, disabled: false })
      }
      store.state.diyForm.sealFormData.forms[data.id] = undefined
    })

    notification['error']({
      message: '提示',
      description: '该印章保管员为空，请联系管理员'
    })

    return
  }

  return true
}

const onChange = (e, data) => {
  console.log('下拉选中', e, data)
  if (data.fixType && data.fixType === 'REMOTE') {
    let list = store.state.diyForm.controlList
    // 盖印方式为远程时，展示远程确认人
    // const remote = data.props.options.filter(item => item.includes('远程'))
    let obj = { fixType: 'REMOTE', operation: '' }
    e === '远程盖印' ? (obj.operation = 'add') : (obj.operation = 'del')
    store.commit('INSERT_FORM_ITEM', obj)
  }
  // console.log(e, '---------------')
  // console.log(data, '-----------')
  if (data.titles === '盖印人') {
    if (e == '保管员') {
      const vaild = validate(data, 1)
      if (!vaild) return
      //保管员显示（控制第一次没有选择印章默认选择的保管员）
      store.commit('UPDATE_SELECT_SHOW', { ...data, typeId: 1 })
    }
  }
  if (data.titles === '申请印章') {
    let id = data.id.slice(9)
    let sealer = store.state.diyForm.sealFormData.forms['sealer' + id]

    if (sealer == '保管员') {
      const vaild = validate(data, 2)
      if (!vaild) return
      //保管员显示（控制第一次没有选择印章默认选择的保管员）
      store.commit('UPDATE_SELECT_SHOW', { ...data, typeId: 2 })
    }
    pervSealId.value = e

    // 更改印章列表状态
    store.commit('SET_SEAL_LIST_DISABLED', { key: data.id, value: e })
  }
  emit('change')
}

onMounted(() => {
  console.log('sealForm的值', store.state.diyForm.sealFormData.forms)
  console.log('context.attrs的值', attrs.props)
  console.log('attrs.id的值', attrs.id)
  console.log('attrs的值', attrs)
  if(attrs.titles === '盖印人') {
    sealForm.value[attrs.id] = '申请人';
  }
})

</script>
<style lang="less" scoped></style>
