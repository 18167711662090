<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-20 13:41:40
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-03 20:38:35
 * @FilePath: \yda_web_manage\src\components\diyForm\components\dateTimeRange.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <a-range-picker
    v-bind="$attrs"
    :show-time="showTime($attrs.props.format)"
    :format="$attrs.props.format.includes('HH:mm') ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'"
    separator="→"
    allowClear
    :disabledDate="current => disabledTime(current, $attrs)"
    :placeholder="$attrs.props.placeholder"
    :getCalendarContainer="trigger => trigger.parentElement"
    @change="onChange($event, $attrs.id, $attrs.props.format)"
  >
    <template #suffixIcon>
      <img src="@/assets/images/diyForm/time.png" alt="" class="time" />
    </template>
  </a-range-picker>
  <!-- <div
    class="duration"
    :style="{ marginBottom: $attrs.props.tipsText ? '8px' : '' }"
    v-if="form[$attrs.id] && $attrs.titles !== '起始时间'"
  > -->
  <div class="duration" v-if="form[$attrs.id] && $attrs.titles !== '起始时间'">
    {{ $attrs.props.durationTitle }} ：{{ duration }}{{ $attrs.props.format === 'yyyy-MM-DD' ? '天' : '小时' }}
  </div>
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>

<script setup>
// import tips from './tips.vue'
import { computed, defineEmits, useAttrs, ref, onMounted } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
const store = useStore()
const emit = defineEmits(['change'])
const form = computed(() => store.state.diyForm.form)
const duration = ref('')
const disabledTime = (current, data) => {
  if (data.id === 'takeOutId1') {
    return current && current < moment().subtract(1, 'days')
  }
  return null
}
const showTime = computed(() => type => {
  if (type.includes('HH:mm')) {
    return { format: 'HH:mm' }
  }
  return false
})
const onChange = (date, id, type) => {
  console.log('date的值', date)
  date.length > 0 ? (form.value[id] = dateConversion(date, type)) : (form.value[id] = null)
  if (type === 'yyyy-MM-DD') {
    const temporaryTime = moment(form.value[id][1]).diff(moment(form.value[id][0]), 'days')
    duration.value = temporaryTime + 1
  } else {
    const temporaryTime = moment(form.value[id][1]).diff(moment(form.value[id][0]), 'minutes')
    duration.value = (Number((temporaryTime / 60).toFixed(1)) * 100) / 100
  }
  emit('change')
}
const dateConversion = (dateList, type) => {
  return dateList.map(item => {
    return moment(item).format(type)
  })
}
onMounted(() => {
  const attrsData = useAttrs()
  if (form.value[attrsData.id]) {
    let time = moment(form.value[attrsData.id][1]).diff(
      moment(form.value[attrsData.id][0]),
      attrsData.props.format === 'yyyy-MM-DD' ? 'days' : 'minutes'
    )
    if (attrsData.props.format === 'yyyy-MM-DD') return (duration.value = time + 1)
    duration.value = (Number((time / 60).toFixed(1)) * 100) / 100
  }
})
</script>
<style scoped lang="less">
.time {
  width: 14px;
  height: 14px;
}
.duration {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin-top: 4px;
}
:deep(.ant-calendar-picker-container) {
  left: -125px !important;
  .ant-calendar-range {
    width: 500px;
  }
  .ant-calendar-time-picker {
    top: 76px;
  }
}
</style>
