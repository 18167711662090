<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-18 15:06:52
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-04 15:14:50
 * @FilePath: \yda_web_manage\src\components\diyForm\index.vue
 * @Description: 自定义表单
-->
<template>
  <div>
    <a-form
      ref="applyForm"
      :rules="rules"
      :model="form"
      :labelCol="{
        span: 6,
        style: { paddingRight: '4px', lineHeight: 'inherit', marginTop: '6px' }
      }"
      :wrapperCol="{ style: { width: '306px' } }"
      class="form-box"
    >
      <div v-for="item in formDataList" :key="item.id">
        <DiyFormItem
          v-if="item.controlName !== 'SealInfo' && item.controlName !== 'TakeOut'"
          :controlName="item.controlName"
          v-bind="item"
          v-model:value.trim="form[item.id]"
          @blur="onBlur"
        />
        <div v-else>
          <!-- 是否外带 -->
          <DiyFormItem
            v-if="item.controlName === 'TakeOut'"
            :controlName="item.controlName"
            v-bind="item"
            @blur="onBlur"
            @change="onChange"
          />
          <!-- 印章列表 -->
          <DiyFormItem v-else :controlName="item.controlName" v-bind="item">
            <template #sealList v-if="item.controlName && item.controlName === 'SealInfo'">
              <div class="seal-list-box">
                <a-form
                  ref="sealFormRef"
                  :rules="sealRules"
                  :model="sealForms"
                  :labelCol="{
                    style: {
                      width: '85px',
                      paddingRight: '4px',
                      lineHeight: '32px'
                    }
                  }"
                  :wrapperCol="{ style: { width: '306px' } }"
                >
                  <SealListItem
                    v-show="index + 'seal'"
                    v-for="(item, index) in sealList"
                    :key="item"
                    :data="item"
                    :index="index + 1"
                    @blur="onBlur"
                    @change="onChange"
                  />
                </a-form>
                <!-- <a-button class="add-button" @click="sealAdd" :disabled="sealList.length >= 30" -->
                <a-button class="add-button" @click="sealAdd(item)" v-if="sealList[0][0].props.options.length !== 1"
                  ><PlusOutlined class="add-icon" />添加印章</a-button
                >
              </div>
            </template>
          </DiyFormItem>
        </div>
      </div>
    </a-form>
    <div class="approve" :style="{ paddingBottom: !approveShow ? '92.8px' : '52.8px' }">
      <p class="title">审批信息</p>
      <p class="prompt" v-if="!approveShow" :class="{ reminder: tips.includes('当前审批流程') }">
        {{ tips }}
      </p>
      <section v-else class="approval-flow-box">
        <ApprovalFlow :approvalInfo="approvalFlow" :isApprove="false" />
      </section>
    </div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1
      }"
    >
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="onSubmit" :loading="submitLoading" :disabled="tips.includes('当前审批流程')"
        >提交</a-button
      >
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits, defineProps, createVNode, onMounted } from 'vue'
import { Modal, notification } from 'ant-design-vue'
import { PlusOutlined, ExclamationOutlined } from '@ant-design/icons-vue'
import DiyFormItem from './components/diyFormItem.vue'
import SealListItem from './components/sealListItem.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import { cmsNotice } from '@/utils/utils'
import { useStore } from 'vuex'
import { applyAdd, getapplyData, reApplySubmit } from '@/apis/seal'

const store = useStore()
const applyForm = ref()
const emits = defineEmits(['onClose', 'onSubmit', 'reselect'])
const props = defineProps({
  // 审批信息
  approvalFlow: {
    type: Array,
    defaults: () => {
      ;[]
    }
  }
})
const rules = computed(() => store.state.diyForm.rules)
// 控件数组
const formDataList = computed(() => store.state.diyForm.controlList)
// form表单绑定
const form = computed(() => store.state.diyForm.form)
// 印章列表form所需
const sealList = computed(() => store.state.diyForm.sealFormData.sealList)
const sealForms = computed(() => store.state.diyForm.sealFormData.forms)
const sealRules = computed(() => store.state.diyForm.sealFormData.rules)
const recordId = computed(() => store.state.diyForm.recordId)
const processId = computed(() => store.state.diyForm.processId)
const qrcodeForm = computed(() => store.state.diyForm.qrcodeForm)
const sealFormRef = ref()
const approveShow = ref(false)
const submitLoading = ref(false)
const modelEditshow = ref(false) //当前流程已修改提示弹框
const approvalFlow = ref([])
const tips = ref('填完所有必填项后，展示审批信息')

onMounted(() => {
  console.log('表单内容', store.state.diyForm.controlList)
  console.log('印章列表的值', store.state.diyForm.sealFormData.sealList)
  if (props.approvalFlow) {
    approvalFlow.value = props.approvalFlow
    if (props.approvalFlow.length > 0) {
      approveShow.value = true
    }
  }
})
watch(
  () => props.approvalFlow,
  val => {
    if (val) {
      approvalFlow.value = val
      approveShow.value = val.length > 0
    }
  },
  { deep: true }
)

// 添加印章事件
const sealAdd = item => {
  const dateNum = new Date().getTime()
  const sealDataList =
    sealList.value.length > 0
      ? JSON.parse(JSON.stringify(sealList.value[0]))
      : [
          {
            id: 'applySeal0',
            titles: '申请印章',
            controlName: 'SelectInput',
            value: '',
            valueType: 'Number',
            props: {
              required: true,
              enablePrint: true,
              expanding: false,
              options: [{ disabled: false, value: '', label: '' }]
            }
          },
          {
            id: 'applyNum0',
            titles: '申请次数',
            controlName: 'NumberInput',
            value: '',
            valueType: 'Number',
            props: {
              required: true,
              enablePrint: true
            }
          },
          {
            id: 'sealer0',
            titles: '盖印人',
            controlName: 'SelectInput',
            value: '',
            valueType: 'String',
            props: {
              required: true,
              options: ['申请人', '保管员']
            }
          }
        ]

  sealDataList.forEach(element => {
    let index = sealList.value.length || 0
    let obj = {
      申请印章: 'applySeal',
      申请次数: 'applyNum',
      盖印人: 'sealer'
    }
    //初次默认选择保管员控制保管员隐藏（这时没选择印章）
    if (element.isInitShow) {
      element.isInitShow = false
    }

    element.id = obj[element.titles] + dateNum
    // element.id = obj[element.titles] + index
  })
  store.commit('INSERT_SEAL_ITEM', { sealDataList, item, index: sealList.value.length || 0, date: dateNum })
}
// 取消事件
const onClose = () => {
  console.log(formDataList, '789')
  emits('onClose')
  setTimeout(() => {
    store.commit('EMPTY_ALL_DATA')
  }, 100)
}
const rulesForm = () => {
  let flags = true
  // return
  formDataList.value.forEach(async it => {
    if (it.controlName == 'Phone' && form.value[it.id]) {
      applyForm.value.validateFields(it.id)
      let reg1 = new RegExp(
        /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$|((^0\d{2,3})-?)?\d{7,8}$|^400[016789]\d{6}$|^400-[016789]\d{2}-\d{4}$/
      )
      // let reg2 = new RegExp(/^[1][3-9]\d{9}$/)|| reg2.test(form.value[it.id])
      flags = reg1.test(form.value[it.id])
      if (!flags) {
        cmsNotice('error', `请输入正确的${it.titles}`)
        flags = false
        return
      }
    }
  })
  return flags
}
// 提交事件
const onSubmit = async () => {
  console.log('store', store)
  if (checkStatus() && rulesForm()) {
    submitLoading.value = true
    let obj = {
      // TODO：增加 deptId
      deptId: store.state.diyForm.deptId,
      processDefinitionId: store.state.diyForm.processDefinitionId,
      processId: store.state.diyForm.processId,
      formData: JSON.stringify(finalyResult())
    }
    //二维码水印设置
    if (qrcodeForm.value.qrcodeType == 1) {
      obj = { ...obj, qrcodeSize: qrcodeForm.value.qrcodeSize, qrcodePositionType: qrcodeForm.value.qrcodePositionType }
    }
    if (recordId.value) obj['id'] = recordId.value
    const res = recordId.value ? await reApplySubmit(obj) : await applyAdd(obj)
    submitLoading.value = false
    if (res.code === 0) {
      cmsNotice('success', '您已成功提交申请！')
      store.commit('EMPTY_ALL_DATA')
      return emits('onSubmit', 'submit')
    }
    if (res.code === 8019) {
      cmsNotice('error', '部分印章停用，请重新选择印章')
      return store.commit('SET_SEAL_DEACTIVATE_ID', res.message.split(','))
    }
    processChangeModelDeal(res.code, res.message)
  } else {
    Promise.all([applyForm.value.validate(), sealFormRef.value[0].validate()])
  }
}

// 业务流程变动弹窗处理
const processChangeModelDeal = (code, message) => {
  // 业务流程有修改 弹窗提示
  const modalObj = {
    centered: true,
    title: '业务流程修改',
    icon: createVNode(ExclamationOutlined),
    content: message,
    okText: '确定',
    onOk() {
      modelEditshow.value = false
      //重新选择流程名称
      emits('reselect', 'edit')
    }
  }
  if (code === 8003 || code === 8004) {
    modalObj.title = code === 8003 ? '业务流程停用' : '业务流程不可用'
    modalObj.okText = '重新选择业务流程'
    modalObj.cancelText = '确定'
    modalObj['onCancel'] = () => {
      console.log('点击了确定')
    }
    modalObj.onOk = () => {
      emits('reselect')
    }
    return Modal.confirm(modalObj)
  }
  if (code === 8006 && !modelEditshow.value) {
    modelEditshow.value = true
    return Modal.warning(modalObj)
  }
}

// 处理盖印方式数据
const dealRemoteData = data => {
  const typeList = ['常规', '远程', '连续']
  let remoteIndex = typeList.findIndex(item => data.includes(item))
  let obj = {
    remote: remoteIndex + 1
  }
  if (data.includes('远程')) {
    obj['remoteStaffId'] =
      typeof form.value['confirmerId1'] === 'object' ? form.value['confirmerId1'].staffId : form.value['confirmerId1']
  }
  return [obj]
}
// 处理外带数据
const dealTakeOutData = () => {
  // debugger
  // console.log('走进来了哦', form.value['takeOutId2']);
  // return
  console.log('处理外带数据', form.value['takeOutId0'])
  let obj = {}
  if (form.value['takeOutId0']) {
    let addressList = []
    form.value['takeOutId2'][0]?.addressList.forEach(i => {
      if (i.address) {
        let it = i
        if (it.lng) it.lon = it.lng
        addressList.push(it)
      }
    })
    obj = {
      takeout: 2,
      startTime: form.value['takeOutId1'] ? form.value['takeOutId1'][0] : '',
      finishTime: form.value['takeOutId1'] ? form.value['takeOutId1'][1] : '',
      lon: form.value['takeOutId2'] ? form.value['takeOutId2'][0].lng : '',
      lat: form.value['takeOutId2'] ? form.value['takeOutId2'][0].lat : '',
      address: form.value['takeOutId2'] ? form.value['takeOutId2'][0].address : '',
      addressList: addressList
    }
    return [obj]
  }
  return [{ takeout: 1 }]
}
// 最后提交的formData数据
const finalyResult = () => {
  // 深拷贝出来，不修改原数据
  const formData = JSON.parse(JSON.stringify(form.value))
  console.log('formData',formData)
  // 处理印章的数据
  let sealForm = store.state.diyForm.sealFormData.forms
  let sealData = store.state.diyForm.sealFormData.sealList.map(item => {
    return {
      sealId: sealForm[item[0].id],
      applicationNumber: sealForm[item[1].id],
      affixedType: sealForm[item[2].id] === '保管员' ? 2 : 1
    }
  })
  // 特殊类型数组
  const specialTypeList = ['FILE_NAME', 'SEAL_INFO', 'REMOTE', 'TAKE_OUT', 'TASK_CODE']
  specialTypeList.forEach(item => {
    console.log('特殊类型的item', item)
    // const index = formDataList.value.findIndex(el => el.type && el.type === item)
    const index = formDataList.value.findIndex(el => el.fixType && el.fixType === item)
    let valueData
    console.log('valueDataindex', index)
    if (index != -1) {
      switch (item) {
        case 'FILE_NAME':
          valueData = [{ fileName: form.value[formDataList.value[index].id] }]
          break
        case 'SEAL_INFO':
          valueData = sealData
          break
        case 'TASK_CODE':
          valueData = [{ taskCode: form.value[formDataList.value[index].id] }]
          break;
        case 'REMOTE':
          valueData = dealRemoteData(form.value[formDataList.value[index].id])
          break
        default:
          valueData = dealTakeOutData()
          break
      }
    }
    console.log('看看valueData的值', valueData)
    if (index > -1) {
      formData[formDataList.value[index].id] = valueData
    }
  })
  // 删除无用key0
  const uselessKey = ['confirmerId1', 'takeOutId0', 'takeOutId1', 'takeOutId2']
  uselessKey.forEach(item => {
    delete formData[item]
  })
  return formData
}
// changeRules()
const changeRules = () => {
  let form = store.state.diyForm
  console.log('rules', form)
  // form.rules
  let flag = false
  for (let i in form.rules) {
    console.log('rules', i)
    if (i == 'takeOutId2') flag = true
  }
  if (flag) {
    console.log('有的')
    // form.rules.takeOutId2[0].trigger = 'change'
    form.rules.takeOutId2[0].validator = addressChange
  }
}

const addressChange = (rule, value) => {
  console.log('我变了', value)
  let flag = false
  if (value) {
    value[0].addressList.map(i => {
      if (!i.isCheck) flag = true
    })
  } else {
    return Promise.reject('111')
  }
  if (flag) {
    return Promise.reject('111')
  } else {
    return Promise.resolve()
  }
}
// 失焦事件
const onBlur = e => {
  // changeRules()
  console.log('进来了啊')
  if (checkStatus()) {
    let data = {
      processDefinitionId: store.state.diyForm.processDefinitionId,
      processId: store.state.diyForm.processId,
      departmentId: store.state.diyForm.deptId,
      params: finalyResult()
    }
    getapplyData(data)
      .then(res => {
        if (res.code === 0) {
          approvalFlow.value = res.data
          approveShow.value = true
        }
        if (res.code === 8003 || res.code === 8004 || res.code === 8006) {
          processChangeModelDeal(res.code, res.message)
        }
        if (res.code === 8010 || res.code === 8013) {
          approveShow.value = false
          tips.value = res.message
        }
        console.log(res, '我看看审批')
      })
      .catch(err => {
        console.log(12346)
      })
  }
  return
}
const checkStatus = () => {
  // 印章列表表单校验状态
  if (!sealRules.value && Object.keys(sealForms.value).length <= 0 && processId.value) {
    notification['warning']({
      message: '提示',
      description: '请添加印章'
    })
    return false
  }
  let sealVerifyStatus = disposeVerify(sealRules.value, sealForms.value)
  changeRules()
  // 整体表单教校验状态
  let formVerifyStatus = disposeVerify(rules.value, form.value)
  // changeRules()
  return sealVerifyStatus && formVerifyStatus
}
// 尝试解决onChange没有声明问题
const onChange = e => {
  console.log(e, '我是陈驰')
}
// 处理校验函数
const disposeVerify = (rules, form) => {
  if (rules && Object.keys(form).length > 0) {
    let keyList = Object.keys(rules)
    let status = true
    for (let index = 0; index < keyList.length; index++) {
      if (form[keyList[index]] === undefined) {
        return (status = false)
      }
      if (rules[keyList[index]][0].type === 'array') {
        if (form[keyList[index]].length < 1) {
          return (status = false)
        }
      }
      if (rules[keyList[index]][0].type === 'string') {
        if (form[keyList[index]].length < 1) {
          return (status = false)
        }
      }
    }
    return status
  }
  return false
}
</script>
<style scoped lang="less">
:deep(.ant-form-item-required::before) {
  margin-right: 6px;
}
:deep(.ant-col-6) {
  max-width: 108.95px;
}
:deep(form textarea.ant-input) {
  height: 88px;
}
// :deep(.ant-row) {
// align-items: center;
// }
:deep(.isAddress) {
  align-items: flex-start;
}
:deep(.isText) {
  align-items: flex-start;
}
:deep(.isFile) {
  align-items: flex-start;
}
:deep(.isDate) {
  align-items: flex-start;
}
:deep(.isTips) {
  margin-bottom: 0;
}
:deep(.ant-input) {
  border-radius: 2px;
}
:deep(.ant-input-number) {
  width: 100% !important;
  .ant-input-number-handler-wrap {
    display: none;
  }
}
:deep(.ant-select) {
  .ant-select-selector {
    min-height: 32px;
    border-radius: 2px;
  }
}

:deep(.ant-calendar-picker) {
  width: 100%;
}
:deep(.apply-box) {
  padding-top: 0;
  padding-bottom: 40px;
}
.form-box {
  padding: 32px 24px 0;
  overflow: hidden;
}
.approve {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 24px 52.8px;
  .title {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
  .prompt {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
  }
  .reminder {
    color: rgba(255, 147, 0, 1);
  }
  .approval-flow-box {
    width: 485px;
    overflow: scroll;
  }
}
// 印章列表
.seal-list-box {
  margin-bottom: 24px;
  :deep(.ant-form-item) {
    margin-bottom: 0;
  }
  .add-button {
    margin-left: 25px;
    font-size: 14px;
    background-color: #0A7BFF;
    color: #ffffff;
    border: none;
    box-shadow: none;
    border-radius: 2px;

    .add-icon {
      font-size: 12px;
    }
  }
  .add-button:hover,
  .add-button:focus,
  .add-button:active {
    background-color: #0A7BFF;
    color: #ffffff;
  }
}
</style>
