<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-23 16:57:31
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-10-08 09:39:16
 * @FilePath: \yda_web_manage\src\components\diyForm\components\sealListItem.vue
 * @Description: 用印申请表单-印章列表
-->
<!--  -->
<template>
  <div class="seal-item-box" :class="{ deactivate: getDeactivateStatus }">
    <!-- 头部 -->
    <div class="seal-item-top">
      <div class="title-box">
        <div class="tag">{{ index }}</div>
        <p class="title">印章{{ numberToChanie(index) }}</p>
      </div>
      <div class="del-btn" @click="onDelete(index)" v-if="index > 1">
        <IconFont type="icon-delete" class="dels-icon" />
        <span>删除</span>
      </div>
    </div>
    <div v-for="(item, index) in data" :key="index + 'sel'" class="form-item-box">
      <DiyFormItem
        v-bind="item"
        :controlName="item.controlName"
        v-model:value.trim="sealForm[item.id]"
        @blur="$attrs.onBlur"
      />
      <!-- info：保管员显示条件 -->
      <div v-if="item.titles === '盖印人' && sealForm[item.id] === '保管员' && item.isInitShow" class="keep-box">
        <span>保管员：</span>
        <a-tooltip v-if="getKeepList(item).length > 20">
          <template #title>{{ getKeepList(item) }}</template>
          {{ getKeepList(item) }}
        </a-tooltip>
        <span v-else>{{ getKeepList(item) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, useAttrs, onMounted, defineProps } from 'vue'
import DiyFormItem from './diyFormItem.vue'
import { numberToChanie } from '@/utils/utils'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 1
  }
})
const sealForm = computed(() => store.state.diyForm.sealFormData.forms)
const sealDeactivateId = computed(() => store.state.diyForm.sealDeactivateId)
// 是否停用了
const getDeactivateStatus = computed(() => {
  if (sealDeactivateId.value) {
    if (sealForm.value[props.data[0].id]) {
      return sealDeactivateId.value.includes(sealForm.value[props.data[0].id].toString())
    }
    return false
  }
  return false
})
// 保管员展示内容
const getKeepList = computed(() => item => {
  const storeKeepObj = store.state.diyForm.sealFormData.keeperObj
  const applySealId = 'applySeal' + item.id.replace('sealer', '')
  if (sealForm.value[applySealId]) {
    return storeKeepObj[sealForm.value[applySealId]].map(el => el.staffName).join('、')
  }
  return ''
})
const onDelete = index => {
  store.commit('SET_DEL_SEAL_ITEM', index - 1)
}
onMounted(() => {
  console.log(useAttrs(), '666666*')
  console.log('data的值', props.data)
})
</script>
<style scoped lang="less">
.seal-item-box {
  width: 432px;
  background: #f5f6f7;
  border-radius: 4px;
  margin: 0 24px 16px;
  padding: 16px 16px 16px 13px;
  .seal-item-top {
    display: flex;
    justify-content: space-between;
    .title-box {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .tag {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #0A7BFF;
        color: white;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        margin-right: 9px;
      }
      .title {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
    }
    .del-btn {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #0A7BFF;
      line-height: 20px;
      .dels-icon {
        color: #0A7BFF;
        margin-right: 6px;
      }
    }
  }
  .form-item-box {
    margin-bottom: 16px;
    .keep-box {
      width: 370px;
      margin-top: 8px;
      padding-left: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .form-item-box:last-child {
    margin-bottom: 0;
  }
}
.deactivate {
  border: 1px solid rgba(195, 22, 28, 1);
}
</style>
