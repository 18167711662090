import { useStore } from 'vuex'
import { getFiles } from '@/apis/seal'
export const useDealDiyFormData = () => {
  const store = useStore()
  // 处理整体控件数据和印章数据
  const dealFormDataAndSealData = (
    processId,
    processDefinitionId,
    formDataItems,
    sealDataList,
    isReSubmit,
    remoteAuditorVO,
    hasSelf,
    resultData
  ) => {
    console.log('remoteAuditorVO1873924678567', remoteAuditorVO)
    store.commit('SET_PROCESS_ID', { processId, processDefinitionId })
    // 处理是否外带数据
    const takeOutIndex = formDataItems.findIndex(item => item.type === 'TAKE_OUT')
    if (!formDataItems[takeOutIndex].props.showTakeOut) {
      formDataItems.splice(takeOutIndex, 1)
    } else {
      formDataItems[takeOutIndex].props.items.unshift({
        titles: '是否外带',
        controlName: 'Switch',
        value: '',
        valueType: 'Boolean',
        props: {
          required: false,
          enablePrint: true,
          tipsText: formDataItems[takeOutIndex].props.tipsText
        }
      })
      formDataItems[takeOutIndex].props.items.forEach((item, index) => {
        item['id'] = 'takeOutId' + index
        if (!item.titles) {
          item['titles'] = item.title
          delete item.title
        }
      })
    }

    // 处理盖印方式中的选项数组
    const remoteIndex = formDataItems.findIndex(item => item.type === 'REMOTE')
    console.log(
      'remoteAuditorVO',
      remoteIndex,
      store.state.diyForm.form[formDataItems[remoteIndex].id],
      store.state.diyForm.form
    )
    if (formDataItems[remoteIndex].props.options) {
      let list = []
      formDataItems[remoteIndex].props.options.forEach(item => {
        typeof item !== 'string' ? (item.isOpen ? list.push(item.name) : '') : list.push(item)
      })
      formDataItems[remoteIndex].props.options = list
    }
    // 替换所有type 解决 组件库 type冲突警告
    formDataItems = formDataItems.map(item => {
      if (item.type) {
        item['fixType'] = item.type
        delete item.type
      }
      item['titles'] = item.title
      delete item.title
      if (['NumberInput', 'AmountInput'].includes(item.controlName)) item.valueType = 'String'
      //处理印章申请人与保管员默认选中
      if (item.controlName == 'SealInfo') store.commit('SET_DEFAULT_SELECT', { item, index: 0, isReSubmit })

      //是否开启二维码水印设置
      if (item.configName == 'Remote') {
        //二维码水印配置
        store.commit('SET_QRCODE_DATA', {
          qrcodeType: resultData.qrcodeType ?? 2, //二维码水印开关 1开，2关
          qrcodePageType: resultData.qrcodePageType ?? 1, //单文件或单页 1.单文件 2.单页
          qrcodeSize: resultData.qrcodeSize ?? 2, //二维码大小 默认 25*25mm
          qrcodePositionType: resultData.qrcodePositionType ?? 1, //二维码位置 默认左上
          firstshowQrcode: isReSubmit // 添加申请首次不展示
        })
      }
      return item
    })
    store.commit('SET_CONTROL_LIST', formDataItems)
    // 不是重新提交的才处理
    if (!isReSubmit) {
      console.log('sealDataList的值', sealDataList)
      dealSealListData(sealDataList)
      // 印章列表的
      // const sealList = store.state.diyForm.sealFormData.sealList
      // sealList[0][0].props.options = sealDataList.map(item => {
      //   return { value: item.sealId, label: item.sealName, disabled: false }
      // })
      // store.commit('SET_SEAL_LIST', sealList)
      // // 同步印章表单和reules
      // store.commit('SET_SEAL_LIST_FORM', sealList)
    }
    // 处理保管员数据
    let keepObj = {}
    sealDataList.forEach(item => {
      keepObj[item.sealId] = item.trusteeList
    })
    store.commit('SET_KEEPER_LIST', keepObj)
    store.commit('SET_REMOTR_PROPLE', [])
    // 设置盖印方式默认值
    if (!store.state.diyForm.form[formDataItems[remoteIndex].id]) {
      store.commit('SET_REMOTE_DEFAULT_VALUE', {
        id: formDataItems[remoteIndex].id,
        value: formDataItems[remoteIndex].props.options[0],
        remoteAuditorVO: remoteAuditorVO,
        hasSelf: hasSelf
      })
    } else if (remoteAuditorVO && isReSubmit) {
      console.log('处理数据异常')
      store.state.diyForm.remoteAuditorVO = remoteAuditorVO ?? []
    }
    if (store.state.diyForm.form[formDataItems[remoteIndex].id] === '远程盖印' && isReSubmit) {
      // 插入【重新提交】远程确认人
      store.commit('INSERT_FORM_ITEM', { fixType: 'REMOTE', operation: 'add', remoteAuditorVO: remoteAuditorVO })
    }
  }

  // 处理印章列表数据
  const dealSealListData = sealDataList => {
    console.log('sealDataList', sealDataList, typeof sealDataList)
    const sealList = store.state.diyForm.sealFormData.sealList
    sealList[0][0].props.options = sealDataList.map(item => {
      return { value: item.sealId, label: item.sealName, disabled: false }
    })

    //TODO：流程名称绑定的印章有且仅有一个
    if (sealDataList.length === 1) {
      let keepObj = {}
      sealDataList.forEach((item, index) => {
        console.log('sealDataList item的值', item)
        let obj = {
          trusteeList: 'sealer',
          sealId: 'applySeal'
        }
        Object.keys(item).forEach(el => {
          console.log('el的值11111', el)
          let data = { key: obj[el] + index, value: item[el] }
          if (el === 'trusteeList') data.value = '保管员'
          console.log('单印章数据处理', data)
          store.commit('SET_RESUBMIT_SEAL_DATA', data)
        })
        keepObj[item.sealId] = item.trusteeList
      })
      //处理保管员数据
      store.commit('SET_KEEPER_LIST', keepObj)
      sealList[0][2].isInitShow = true
    }

    store.commit('SET_SEAL_LIST', sealList)
    // 同步印章表单和reules
    store.commit('SET_SEAL_LIST_FORM', sealList)
  }

  // 处理重新提交的数据
  const dealResubmitData = async (resubmitDta, formDataItems, sealDataList, fileElseList) => {
    // 接口返回 动态表单值
    let formObjDataKey = Object.keys(resubmitDta)
    const formItems = JSON.parse(formDataItems)
    let formObjData = {}
    for (const item of formObjDataKey) {
      for (const el of formItems) {
        if (item === el.id) {
          if (!el.type) {
            formObjData[item] = await dealCustData(resubmitDta[item], el)
          } else {
            formObjData[item] = getFixTypeData(el.type, resubmitDta[item], sealDataList, fileElseList)
          }
        }
      }
    }
    store.commit('SET_RESUBMIT_DATA', formObjData)
  }
  // 重新提交，数据回显，自定义类型数据处理
  const dealCustData = async (formData, item) => {
    if (item.controlName === 'FileUpload') {
      let valueData
      const ids = formData.map(item => item.fileId)
      if (ids.length > 0) {
        const { code, data } = await getFiles({
          ids: ids.join(','),
          isPagination: false
        })
        if (code === 0) {
          valueData = data.map(item => {
            item.url = item.fullUrl
            item.name = item.name + item.suffix
            item.fileId = item.id
            return item
          })
        }
        return valueData
      }
      return []
    }
    return formData
  }
  // 重新提交，数据回显，固定类型处理
  const getFixTypeData = (type, data, sealList, fileElseList) => {
    const typeFunObj = {
      FILE_NAME: () => {
        return data[0].fileName
      },
      SEAL_INFO: () => {
        const sealDataItem = JSON.parse(JSON.stringify(store.state.diyForm.sealFormData.sealList[0]))
        // 已选的ID数组
        const disabledIdList = data.map(item => {
          return item.sealId
        })
        // 处理印章列表
        if (data.length <= 0 || !data) {
          dealSealListData(sealList)
        } else {
          const afterDealData = data.map((item, index) => {
            return sealDataItem.map((el, inde) => {
              console.log('sealDataItem的值', el)
              let obj = JSON.parse(JSON.stringify(el))
              obj.id = obj.id.replace(obj.id.replace(/[^0-9]/g, ''), index)

              if (inde === 0) {
                obj.props.options = sealList.map(elem => {
                  let modelObj = { value: elem.sealId, label: elem.sealName, disabled: false }
                  modelObj.disabled = disabledIdList.includes(modelObj.value)
                  return modelObj
                })
              }

              //重新提交可以默认显示保管员，这时印章以选择（区分添加申请默认选择保管员没有选择印章，不用立即显示保管员）
              if (obj.id.indexOf('sealer') > -1) {
                obj.isInitShow = true
              }
              return obj
            })
          })

          // 同步到VUEX中sealList 和forms
          store.commit('SET_SEAL_LIST', afterDealData)
          store.commit('SET_SEAL_LIST_FORM', afterDealData)
          // 为forms设置value值
          data.forEach((item, index) => {
            console.log('item1111111', item, index)
            let obj = {
              affixedType: 'sealer',
              sealId: 'applySeal',
              applicationNumber: 'applyNum'
            }
            Object.keys(item).forEach(el => {
              console.log('el的值1111', el)
              let data = { key: obj[el] + index, value: item[el] }
              if (el === 'affixedType') data.value = item[el] === 1 ? '申请人' : '保管员'
              console.log('处理后的data的值', data)
              store.commit('SET_RESUBMIT_SEAL_DATA', data)
            })
          })
        }

        return undefined
      },
      FILE: () => {
        return data.map(item => {
          return {
            fileId: item.fileId,
            name: item.fileName + '.' + item.suffix,
            url: item.fullFile
          }
        })
      },
      FILE_ELSE: () => {
        return data.map(item => {
          const index = fileElseList.findIndex(el => el.fileId === item.fileId)
          return {
            url: fileElseList[index].fullFile,
            name: fileElseList[index].fileName + '.' + fileElseList[index].suffix,
            fileId: fileElseList[index].fileId
          }
        })
      },
      REMOTE: () => {
        let obj = { 1: '常规盖印', 2: '远程盖印', 3: '连续盖印' }
        if (data[0].remote === 2) {
          store.state.diyForm.form['confirmerId1'] = data[0].remoteStaff
        }
        return obj[data[0].remote]
      },
      TAKE_OUT: () => {
        console.log('这里的数据', data[0].addressList)
        let list = data[0].addressList
        if (list) {
          list = list.map(i => {
            return {
              lng: i.lon,
              lat: i.lat,
              address: i.address,
              isCheck: true
            }
          })
        } else {
          list = []
          list[0] = { isCheck: false }
        }

        const obj = {
          takeOutId0: data[0].takeout === 2,
          takeOutId1: [data[0].startTime, data[0].finishTime],
          takeOutId2: [
            {
              address: data[0].address,
              lng: data[0].lon,
              lat: data[0].lat,
              name: data[0].address,
              addressList: list
            }
          ]
        }
        store.commit('SET_RESUBMIT_DATA', obj)
        store.commit('SET_SWITCH_STATUS', data[0].takeout === 2)
      }
    }
    return typeFunObj[type]()
  }
  return {
    dealFormDataAndSealData,
    dealResubmitData
  }
}
