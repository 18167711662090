<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-28 11:20:25
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-09 11:58:55
 * @FilePath: \yda_web_manage\src\components\diyForm\components\remoteStaff.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div>
    <!-- <a-button v-if="remoteStaffList.length < 1" class="add-remote" @click="remoteVisible = true">
      +
    </a-button>-->
    <div v-if="isUser" class="no-remote">
      暂无远程确认人，请联系管理员
    </div>
    <img :src="addIcon" alt v-else-if="remoteStaffList.length < 1" @click="openModal" class="add-remote" />
    <!-- showPoint 控制是否显示右上角叉号 -->
    <approval-staff-time-list
      v-else
      :approvalList="remoteStaffList"
      :showPoint="diyForm.remoteAuditorVO == null || diyForm.remoteAuditorVO.length !== 1"
      @delete-approval="deleteRemoteStaffList"
    />
    <!-- <RemoteConfirm
      v-model:remoteVisible="remoteVisible"
      :list="check.searchLbwList"
      @submitModel="submitModel($event, $attrs.id)"
    /> -->

    <a-modal
      centered
      title="选择远程确认人"
      width="655px"
      class="global-range-modal"
      :visible="remoteVisible"
      destroyOnClose
      @ok="submitModel($event, $attrs.id)"
      @cancel="closeModal"
    >
      <GlobalSelector
        :visible="remoteVisible"
        :selectorInfo="modalConfig.selectorInfo"
        :renderList="modalConfig.staffList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        @changeData="changeData"
        ref="globalSelector"
      />
    </a-modal>
  </div>
</template>
<script>
false
</script>
<script setup>
import RemoteConfirm from '@/views/seal/apply/components/remoteConfirm'
import ApprovalStaffTimeList from '@/views/components/approvalStaffTimeList'
import WrapModal from '@/components/WrapModal/index'
import GlobalSelector from '@/components/GlobalSelector'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'

import { getStaffRemoteList } from '@/apis/businessManage'
import { ref, reactive, computed, defineEmits, onMounted, useAttrs, watch } from 'vue'
import { useStore } from 'vuex'
import { emit } from 'process'
import addIcon from '@/assets/images/apply/add-icon.png'
import { getDepartmentOrStaff } from '@/apis/businessManage'

const store = useStore()
const remoteVisible = ref(false)
const globalSelector = ref(null)
const isUser = ref(false) // 是否是本人

const modalConfig = reactive({
  // 弹窗配置
  selectorInfo: {
    placeholder: '请输入员工姓名或部门',
    abelCheckAll: true,
    ableSelectDep: true,
    ableChangeData: true,
    limitCount: 1
  },
  staffList: [], // 所有可选人员
  list: []
})
const getProcessStaff = async list => {
  // 远程确认人
  let data = []
  if (list) {
    data = list
  } else {
    const res = await getStaffRemoteList()
    data = res.data
  }
  modalConfig.staffList = formateListData({ data: { staffList: data } })
  modalConfig.list = formateListData({ data: { staffList: data } })
}
const selectorType = ref(1)

// const getSearchInfo = search => {
//   modalConfig.staffList = []
//   modalConfig.list.map(item => {
//     if (item.dataName.includes(search)) {
//       modalConfig.staffList.push(item)
//     }
//   })
// }

const attrsData = useAttrs()
const emits = defineEmits(['blur'])
const form = computed(() => store.state.diyForm.form)
const check = reactive({
  searchLbwList: []
})
const remoteStaffList = ref([])

// 删除
const deleteRemoteStaffList = () => {
  remoteStaffList.value = []
  getList()
  form.value[attrsData.id] = undefined
  emits('blur')
}

const submitModel = (data, id) => {
  // console.log('globalSelector.value.okEvent()', globalSelector.value.okEvent())
  if (!globalSelector.value.okEvent().length) return
  let it = [...globalSelector.value.okEvent()]
  transformData(it).then(res => {
    if (!res[0].staffId) {
      //搜索后转化来的数据需要格式化成当前需要的数据格式
      res[0].staffId = res[0].dataId
      res[0].fullAvatar = res[0].icon
      res[0].staffName = res[0].dataName
    }

    console.log('选择的远程确认人信息', res)
    console.log('res', res[0])
    remoteStaffList.value.push(res[0])
    form.value[id] = String(res[0].staffId)
    closeModal()
    emits('blur')
  })
}

const openModal = async () => {
  selectorType.value = 1
  await getScopeList()
  remoteVisible.value = true
}

const closeModal = () => {
  remoteVisible.value = false
  globalSelector.value.cancelEvent()
}

const diyForm = computed(() => store.state.diyForm)

onMounted(() => {
  console.log('diyForm.value.remoteAuditorVO', diyForm.value)
  // 先获取到所有的远程确认人
  console.log(attrsData)
  if (form.value[attrsData.id]) {
    remoteStaffList.value.push(form.value[attrsData.id])
    if (!diyForm.value.remoteAuditorVO.length) {
      getList()
    }
    console.log('remoteStaffList', remoteStaffList.value)
  } else {
    getList()
  }
})

const getList = () => {
  console.log('diyForm.value.remoteAuditorVO', diyForm.value)
  const remoteAuditorVO = diyForm.value.remoteAuditorVO
  console.log('remoteAuditorVO', remoteAuditorVO)
  if (diyForm.value.hasSelf && remoteAuditorVO === null) {
    isUser.value = true
  } else if ((!remoteAuditorVO || !remoteAuditorVO.length) && !diyForm.value.hasSelf) {
    getProcessStaff()
  } else if (remoteAuditorVO.length === 1) {
    // 是否只有一位 并且不是本人
    remoteStaffList.value = [
      {
        staffId: remoteAuditorVO[0].id,
        staffName: remoteAuditorVO[0].name,
        avatarId: remoteAuditorVO[0]?.avatarId,
        fullAvatar: remoteAuditorVO[0]?.fullAvatar
      }
    ]
    form.value[attrsData.id] = String(remoteAuditorVO[0].id)
  } else {
    let list = []
    remoteAuditorVO.forEach(element => {
      list.push({
        staffId: element.id,
        staffName: element.name,
        avatarId: element.avatarId,
        fullAvatar: element?.fullAvatar,
        mobile: element.mobile
      })
    })
    // 多位
    getProcessStaff(list)
  }
}

// 切换数据
const changeData = ({ isOrganizationData, searchValue }) => {
  console.log('切换的数据的类型', isOrganizationData)
  if (isOrganizationData) {
    // 组织架构数据
    selectorType.value = 1
  } else {
    //人员数据
    selectorType.value = 3
  }
  getSearchInfo(searchValue)
}
//获取搜索信息
const getSearchInfo = e => {
  console.log('搜索的值嘞', e)
  let data = {
    searchName: e
  }
  getScopeList(data)
}

//弹窗数据
const getScopeList = async data => {
  let params = {
    selectorType: selectorType.value, //1部门人员2部门3员工
    allData: true
  }
  const res = await getDepartmentOrStaff({ ...params, ...data })
  if (res.success) {
    handelData(res)
  }
}

//处理左侧数据
const handelData = res => {
  console.log('获取的所有数据', res)
  modalConfig.staffList = formateListData(res)
  console.log('格式化后的数据', modalConfig.staffList)
}

//去下级
const toNext = id => {
  let data = {
    departmentId: id
  }
  getScopeList(data)
}
</script>
<style scoped lang="less">
.no-remote {
  color: #0A7BFF;
  margin-top: 5px;
}
.add-remote {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
// /deep/.name-box {
//   font-size: 16px !important;
//   .name {
//     font-size: 16px !important;
//   }
// }
// /deep/.icon {
//   width: 40px !important;
//   height: 40px !important;
// }
</style>
