<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-20 10:48:50
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-08 14:25:19
 * @FilePath: \yda_web_manage\src\components\diyForm\components\dateTime.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <a-date-picker
    v-bind="$attrs"
    :showTime="showTime($attrs.props.format)"
    :format="$attrs.props.format"
    :placeholder="$attrs.props.placeholder || '请选择'"
    :getCalendarContainer="trigger => trigger.parentElement"
    @change="onChange($event, $attrs.id, $attrs.props.format)"
  />
</template>

<script setup>
import { computed, defineEmits } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
const store = useStore()
const form = computed(() => store.state.diyForm.form)
const showTime = computed(() => type => {
  return type.includes('HH:mm')
})
const emit = defineEmits(['change'])
const onChange = (date, id, type) => {
  if (date) {
    form.value[id] = moment(date).format(type)
  } else {
    form.value[id] = null
  }
  emit('change')
}
</script>
<style scoped></style>
