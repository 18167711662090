<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-28 11:20:25
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-04 10:00:43
 * @FilePath: \yda_web_manage\src\components\diyForm\components\switch.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <a-switch v-bind="$attrs" @change="onChange($event)" />
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>

<script setup>
// import tips from './tips.vue'
import { defineEmits, onMounted, useAttrs, computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const form = computed(() => store.state.diyForm.form)

const attrsData = useAttrs()
const emit = defineEmits(['change', 'blur'])
const onChange = status => {
  console.log('改变开关状态', status)
  store.commit(`SET_SWITCH_STATUS`, status)
  console.log(attrsData, 'hhh0000----', form)
  emit('change', status)
  emit('blur')
}
onMounted(() => {
  console.log('看看attrsData的值', attrsData)
})
</script>
<style scoped lang="less"></style>
