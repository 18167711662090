<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-19 17:42:45
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-10-30 10:24:47
 * @FilePath: \yda_web_manage\src\components\diyForm\components\textareaInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div class="textarea-box">
    <a-textarea v-bind="$attrs" :placeholder="$attrs.props.placeholder || '请输入'" :maxlength="200" />
    <p>{{ form[$attrs.id]?form[$attrs.id].length:0 }}/200</p>
  </div>
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>

<script setup>
// import tips from './tips.vue'
import { computed, onBeforeUnmount, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const form = computed(() => store.state.diyForm.form)
onMounted(()=>{
  const textareaDome = document.getElementsByTagName('textarea')[0]
  const box = document.getElementsByClassName('textarea-box')[0]
  textareaDome.addEventListener('focus',()=>{
    box.style.boxShadow = '0 0 0 2px rgba(195, 22, 28, 0.2)'
    box.style.borderColor = '#CF3838'
    console.log(box, '我设置了没');
  })
  textareaDome.addEventListener('blur',()=>{
    box.style.boxShadow = 'none'
    box.style.borderColor = '#d9d9d9'
  })
})
onBeforeUnmount(()=>{
  const textareaDome = document.getElementsByTagName('textarea')[0]
  textareaDome.removeEventListener('focus',()=>{})
  textareaDome.removeEventListener('blur',()=>{})
})
</script>
<style lang="less" scoped>
.textarea-box {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  p {
    text-align: right;
    padding: 2px 8px 5px 0;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0,0,0,.45);
  }
}
::v-deep.ant-input-affix-wrapper {
  textarea {
    height: 88px;
  }
}
::v-deep .ant-input {
  border: none;
}
::v-deep.ant-input:focus {
  box-shadow: none;
}
</style>
