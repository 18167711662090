<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-18 15:59:24
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-12 17:46:59
 * @FilePath: \yda_web_manage\src\components\diyForm\components\TextInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <a-input
    v-bind="$attrs"
    :placeholder="$attrs.props.placeholder || '请输入'"
    :maxlength="30"
    @change="onChange($event, $attrs.id)"
  />
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>

<script setup>
import { computed, defineEmits, useAttrs } from 'vue'
// import tips from './tips.vue'
import { useStore } from 'vuex'
const attrsData = useAttrs();
const emit = defineEmits(['change'])
const store = useStore()
const form = computed(() => store.state.diyForm.form)
const formDataList = computed(() => store.state.diyForm.controlList)
const onChange = (val, id) => {
  if (form.value[id]) {
    if(attrsData.fixType === "FILE_NAME") {
      let iconRule = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
      // form.value[id] = form.value[id].replace(/[~*'',\[\\\]~@￥……*（）——|【】‘'、\s]/g, '')//特殊字符
      const reg_str = /[^\u4e00-\u9fa5a-zA-Z0-9!"#$%&()*+,-./:;<=>?@\x27\[\\\]^_`{|}~《》]/g
      form.value[id] = form.value[id].replace(reg_str, '') //禁止表情
      return
    }
    form.value[id] = form.value[id].replace(
      /[`~!@#_$%^&*()=|{}':;',\\\[\\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？\s]/g,
      ''
    )
  }

  emit('change')
}
</script>
<style lang="less" scoped></style>
