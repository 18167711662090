<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-19 16:17:31
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-08 14:27:50
 * @FilePath: \yda_web_manage\src\components\diyForm\components\numberInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-input
    v-bind="$attrs"
    :placeholder="$attrs.props.placeholder || '请输入'"
    :maxlength="$attrs.titles === '申请次数' ? 3 : 21"
    @change="onChange($event, $attrs)"
  >
    <template #suffix>
      {{ $attrs.props.unit }}
    </template>
  </a-input>
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>

<script setup>
// import tips from './tips.vue'
import { computed, defineEmits } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const form = computed(() => store.state.diyForm.form)
const sealForm = computed(() => store.state.diyForm.sealFormData.forms)
const emit = defineEmits(['change'])
const onChange = (e, item) => {
  let result = e.target.value.replace(/[^0-9]/g, '')
  if (item.id.includes('applyNum') && item.titles === '申请次数') {
    sealForm.value[item.id] = +result > 0 ? +result : ''
  } else {
    result = result.replace(/^0*(?=\d)/, '')
    form.value[item.id] = +result > 0 ? result : ''
    // if (result.substr(0, 1) <= 0) {
    //   const arr = result.split('')
    //   const index = arr.findIndex(item => item > 0)
    //   form.value[item.id] = result.substr(index)
    // } else {
    //   form.value[item.id] = result
    // }
  }
  emit('change')
}
</script>
<style lang="less" scoped></style>
