<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-19 17:32:10
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-08 14:25:56
 * @FilePath: \yda_web_manage\src\components\diyForm\components\amountInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-input
    v-bind="$attrs"
    :placeholder="$attrs.props.placeholder || '请输入'"
    :maxlength="15"
    @change="onChange($event, $attrs.id)"
  >
    <template #suffix>
      {{ $attrs.props.currencyType === '人民币' ? '元' : $attrs.props.currencyType }}
    </template>
  </a-input>
</template>

<script setup>
import { computed, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { readSync } from 'fs'
const store = useStore()
const form = computed(() => store.state.diyForm.form)
const emit = defineEmits(['change'])
const onChange = (e, id) => {
  let result = e.target.value.replace(/[。，]/g, '.') // 句号转成小数点
  result = result.replace(/[^\d.]/g, '') // 清除非数字和小数点的字符
  result = result.replace(/^\./, '0.') // 第一个字符如果是小数点，则自动添加0
  result = result
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.') // 只保留第一个小数点
  result = result.replace(/^0*(?=\d)/, '') // 开头不允许输入0
  form.value[id] = result
  emit('change')
}
</script>
<style lang="less" scoped></style>
