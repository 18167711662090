<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-20 14:52:25
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-05-30 09:24:26
 * @FilePath: \yda_web_manage\src\components\diyForm\components\address.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div :class="['address-list', attrsData.props.isMultipleAddress && 'change-list']">
    <div :class="['address-w']" style="position: relative;" v-for="(item, index) in addressMessage.addressList"
      :key="item">
      <div :class="[(index != addressMessage.addressList.length - 1) && 'address-item']" style="display: flex;">
        <IconFont type="icon-address" :style="{ color: item.isCheck ? 'rgba(0,0,0,0.85)' : '#3171F4' }" class="address" />
        <span :style="{ color: item.isCheck ? 'rgba(0,0,0,0.85)' : '#3171F4' }" class="address-detail" @click="goMaps(item, index)">{{
          item.isCheck ? item.address : getSpanContent($attrs.titles) }}</span>
      </div>
      <img class="operate-icon" v-if="attrsData.props.isMultipleAddress" @click="changeItem(item, index, $attrs.id, ((addressMessage.addressList.length > 1 && index != addressMessage.addressList.length - 1) || index == 9 ))" :src="((addressMessage.addressList.length > 1 && index != addressMessage.addressList.length - 1) || index == 9 ) ? deleteImg : addImg" />
    </div>
  </div>

  <GdMap v-model:gdMapVisible="gdMapVisible" :from="true" @addressChange="addressChange($event, $attrs.id)"
    :selectAddress="selectAddress || {}" gdMapPlaceholder="根据关键字搜索" gdMapTitle="选择地点" />
  <!-- <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" /> -->
</template>

<script setup>
import { ref, computed, useAttrs, onMounted, defineEmits, reactive } from 'vue'
// import tips from './tips.vue'
import addImg from '@/assets/images/business/add-icon.png';
// import delete from '@/'
import deleteImg from '@/assets/images/business/delete-icon.png'

import GdMap from '@/components/GdMap'
import { useStore } from 'vuex'
const store = useStore()
const gdMapVisible = ref(false)
const selectAddress = ref(null)
const addressMessage = reactive({
  checkedIndex: 0,  // 点击的index
  operate: '',  // 操作：add：添加，change：修改
  type: 'one1', // 单个：single, 多个：multiple
  addressList: [  // 真正选择的
    {
      isCheck: false, // 是否选择了地址s
    }
  ],
})
const attrsData = useAttrs()
const emits = defineEmits(['blur'])
const form = computed(() => store.state.diyForm.form)
// const getColor = computed(() => {
//   return form.value[attrsData.id] && selectAddress.value !== null ? 'rgba(0,0,0,0.85)' : '#3171F4'
// })
const getSpanContent = computed(() => title => {
  return `请选择${title}`
  // return form.value[attrsData.id] && selectAddress.value !== null ? selectAddress.value.address : `请选择${title}`
})
const addressType = computed(() =>() => {
  return attrsData.props.isMultipleAddress
})

const goMaps = (item, index) => {
  // console.log('全部的数据', form.value[attrsData.id][0].addressList);
  addressMessage.operate = item.isCheck ? 'change' : 'add'
  addressMessage.checkedIndex = index;
  gdMapVisible.value = true
}

const addressChange = (value, id) => {
  console.log(value, '999*', form.value[id])
  const obj = { ...value }
  obj.address = obj.name
  delete obj.name
  // if (obj.lng) {
  //   obj.lon = obj.lng
  //   delete obj.lng
  // }

  addressMessage.addressList[addressMessage.checkedIndex] = { ...obj, isCheck: true }
  console.log('是这里的问题', attrsData.props.isMultipleAddress);
  // if (addressMessage.operate == 'add' && addressMessage.addressList.length < 10 && attrsData.props.isMultipleAddress) {
  //   addressMessage.addressList.push({
  //     isCheck: false
  //   })
  // }
  addressMessage.operate = ''

  selectAddress.value = obj
  console.log('处理后的数据', addressMessage.addressList)
  form.value[id] = [{ ...obj, addressList: addressMessage.addressList }]
  // form.value[id] = [{ ...obj, addressList: addressMessage.addressList }]
  emits('blur')
}

// 修改每一项
const changeItem = (item, index, id, isdelete) => {
  console.log('isadd', isdelete, form.value[id], index)
  // isdelete true:是删除
  if (isdelete) {
    addressMessage.addressList.splice(index, 1)
    emits('blur')
  } else {
    addressMessage.addressList.push({
      isCheck: false
    })
  }
  // [{ ...obj, addressList: addressMessage.addressList }]
  if (form.value[id]) {
    // form.value[id].addressList = addressMessage.addressList
    form.value[id].addressList = [{ addressList: addressMessage.addressList }]
  } else {
    form.value[id] = [{ addressList: addressMessage.addressList }]
    // form.value[id] = {
    //   addressList: addressMessage.addressList
    // }
  }
  if (!isdelete) {
    const element = document.getElementsByClassName('change-list')
    if (element[0]) {
      setTimeout(() => {
        element[0].scrollTo(0, 1000);
      }, 100);
    }
    console.log('dddd', element);
  }  
  // form.value[id].addressList = addressMessage.addressList
  // emits('blur')
  // if (item.isCheck) {
  //   addressMessage.addressList.splice(index, 1)
  //   if ((addressMessage.addressList.length && addressMessage.addressList[addressMessage.addressList.length - 1].isCheck) || addressMessage.type == 'multiple') {
  //     addressMessage.addressList.push({
  //       isCheck: false
  //     })
  //   }
  //   if (!addressMessage.addressList.length) {
  //     console.log('是不是这1');
  //     addressMessage.addressList.push({
  //       isCheck: false
  //     })
  //   }
  //   if (addressMessage.addressList.length == 1 && !addressMessage.addressList[0].isCheck) {
  //     console.log('是不是这2', addressMessage.addressList, form.value[id]);
  //     delete form.value[id][0].address
  //   }
  //   form.value[id].addressList = addressMessage.addressList
  //   emits('blur')
  // } else {
  //   addressMessage.checkedIndex = index;
  //   addressMessage.operate = 'add'
  //   gdMapVisible.value = true
  // }
}

onMounted(() => {
  console.log('我是数据', form.value[attrsData.id]);
  addressMessage.type = attrsData.props
  if (form.value[attrsData.id]) {
    selectAddress.value = form.value[attrsData.id][0]
    addressMessage.addressList = form.value[attrsData.id][0].addressList
    // if (attrsData.props.isMultipleAddress && form.value[attrsData.id][0].addressList.length != 10) {
    //   addressMessage.addressList.push({isCheck: false})
    // }
  }
})
</script>
<style scoped lang="less">
span {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.address {
  font-size: 16px;
  margin-right: 4px;
}

.address-list {
  width: 335px;
  max-height: 220px;
  // overflow-x: auto;
  overflow-y: scroll;

  .address-item {
    width: 306px;
    display: flex;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 7px;
    margin-bottom: 12px;
  }

  .address-w {
    width: 330px;
    display: flex;
    justify-content: space-between;
  }
}

.address-detail {
  width: 274px;
}

.operate-icon {
  width: 16px;
  height: 16px;
  // position: absolute;
  // right: -24px;
  // top: 3px;
  cursor: pointer;
}
</style>
