<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-18 16:51:33
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-03-04 15:05:41
 * @FilePath: \yda_web_manage\src\components\diyForm\components\diyFormItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="$attrs.controlName && $attrs.controlName === 'SealInfo'">
    <slot name="sealList"></slot>
    <!-- <SealList /> -->
  </div>
  <div v-if="$attrs.controlName !== 'TakeOut' && $attrs.controlName !== 'SealInfo'">
    <div v-if="isShow($attrs) && $attrs.configName !== 'Remote'">
      <a-form-item
        :label="$attrs.titles + '：'"
        :name="$attrs.id || ''"
        :validateFirst="true"
        :class="[
          {
            isText: $attrs.controlName === 'TextareaInput',
            isFile: $attrs.controlName === 'FileUpload',
            isTips: $attrs.props.tipsText,
            // isAddress: $attrs.controlName === 'Address' && form[$attrs.id] && form[$attrs.id].length > 0,
            isAddress: $attrs.controlName === 'Address',
            addressStyle: $attrs.controlName === 'Address',
            isDate:
              $attrs.controlName === 'DateTimeRange' &&
              !$attrs.id.includes('takeOut') &&
              form[$attrs.id] &&
              form[$attrs.id].length > 0
          }
        ]"
      >
        <component :is="components[$attrs.controlName]" v-bind="$attrs"></component>
      </a-form-item>
      <a-row v-if="$attrs.controlName !== 'Switch'">
        <a-col :span="6"> </a-col>
        <a-col :span="18">
          <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" />
        </a-col>
      </a-row>
      <div v-else style="margin-left: 34px">
        <tips :content="$attrs.props.tipsText" v-if="$attrs.props.tipsText" />
      </div>
    </div>
    <div v-else></div>
  </div>
  <div v-else>
    <div v-for="(item, index) in getPropsList($attrs)" :key="index">
      <DiyFormItem
        :controlName="item.controlName"
        v-bind="item"
        v-if="item.controlName !== 'Switch'"
        v-model:value.trim="form[item.id]"
        @blur="$attrs.onBlur"
      />
      <template v-else>
        <DiyFormItem
          :controlName="item.controlName"
          v-bind="item"
          v-if="!isRequired"
          v-model:checked="form[item.id]"
          @blur="$attrs.onBlur"
        />
        <p v-else class="takeOut-title">外带信息</p>
      </template>
    </div>
  </div>
</template>

<script setup>
import components from '../componentExport'
import tips from './tips.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
const isRequired = ref(false)
const store = useStore()
const form = computed(() => store.state.diyForm.form)
const switchStatus = computed(() => store.state.diyForm.switchStatus)
const showConfirmer = computed(() => store.state.diyForm.showConfirmer)
const getPropsList = computed(() => data => {
  console.log('看看getPropsList获取的值', data)
  // TODO：判断是否必填外带选项
  const list = data.props.items.filter(item => item.titles === '是否外带')
  console.log('list的值', list, data.controlName)
  if (data.controlName === 'TakeOut') {
    // console.log('刚进阿里啊的时候2', switchStatus, data.props.items, list);
    let showList = data.props.items
    console.log('showList的值', showList)

    // switchStatus是否外带的开关
    // TODO：判断是否开启外带必填
    if (data.props.required) {
      isRequired.value = true
      form.value['takeOutId0'] = true
      store.commit(`SET_SWITCH_STATUS`, true)
    }

    console.log('switchStatus的值', switchStatus.value)
    if (switchStatus.value) {
      showList.map(i => {
        console.log('看看i', i)
        if (i.configName === 'Address') {
          i.props.isMultipleAddress = data.props.isMultipleAddress
        }
      })
    }

    return switchStatus.value ? showList : list
  }
  return []
})
const isShow = computed(() => item => {
  if (item.id !== 'confirmerId1') return true
  if (showConfirmer.value) return true
  return false
})
</script>
<style lang="less" scoped>
.takeOut-title {
  font-weight: 500;
  font-size: 14px;
  color: #1a1a1b;
  margin-left: 34px;
  margin-bottom: 24px;
}
.isAddress {
  align-items: center !important ;
}
</style>
